.static {

  &__content {
    padding-top: 80px;

    @include screen-xxl {
      padding-top: 60px; }

    @include screen-md {
      padding-top: 50px; }

    p {
      font-size: 18px;
      line-height: 160%;
      font-weight: 300;
      letter-spacing: -0.02em;
      color: #CFCFCF;
      padding-bottom: 20px;

      @include screen-xxl {
        font-size: 16px;
        padding-bottom: 20px; }

      &:first-child::first-letter {
        font-size: 40px;

        @include screen-xxl {
          font-size: 36px; }

        @include screen-md {
          font-size: 28px; } } }

    strong {
      font-weight: 500;
      color: #f2f2f2; }

    a {
      color: #53F0CE;
      text-decoration: none;
      text-underline-offset: 4px;
      word-break: break-all;

      body:not(.mobile) &:hover {
        text-decoration: underline; }

      strong {
        color: #53F0CE; } }

    ul {
      padding-bottom: 20px;

      @include screen-xxl {
        padding-bottom: 20px; }

      li {
        font-size: 18px;
        line-height: 160%;
        font-weight: 300;
        letter-spacing: -0.02em;
        color: #cfcfcf;
        margin-bottom: 2px;
        padding-left: 42px;
        position: relative;

        @include screen-xxl {
          font-size: 16px;
          padding-left: 32px; }

        @include screen-md {
          padding-left: 17px;
          margin-bottom: 6px; }

        @include screen-sm {
          padding-left: 17px; }

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 16px;
          transform: translateY(-50%);
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #FF9F2F;

          @include screen-xxl {
            left: 10px; }

          @include screen-md {
            width: 5px;
            height: 5px;
            left: 0; } } } }

    hr {
      width: 100%;
      max-width: 220px;
      margin: 20px 0 20px;
      border: none !important;
      border-top: 1px solid #fff !important;

      @include screen-md {
        width: 180px; } } } }
