.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  &__btn {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-shrink: 0;

    @include screen-sm {
      width: 22px;
      height: 22px; }

    svg {
      display: block;
      width: 100%;
      height: 100%;
      stroke: #CFCFCF;
      transition: stroke 300ms ease; }

    body:not(.mobile) &:hover {

      svg {
        stroke: #FF9F2F; } } }

  &__list {
    display: flex;
    align-items: baseline;
    padding: 0 12px;

    @include screen-sm {
      padding: 0 8px; } }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    flex-shrink: 0;
    margin: 0 4px;
    border-radius: 6px;

    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #5D646B;
    background: transparent;
    transition: background 300ms ease, color 300ms ease;

    @include screen-sm {
      width: 28px;
      height: 28px;
      margin: 0 2px;
      border-radius: 4px;
      font-size: 14px;
      line-height: 1; }

    &--delimiter {}

    body:not(.mobile) &:hover:not(.pagination__item--delimiter),
    &.active:not(.pagination__item--delimiter) {
      background: #FF9F2F;
      color: #0C100F; } } }

