.plates {

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-right: -30px;

    @include screen-xxl {
      margin-left: -15px;
      margin-right: -15px; }

    @include screen-md {
      margin-left: -10px;
      margin-right: -10px; }

    @include screen-sm {
      margin: 0; } }

  &__col {
    width: 50%;
    padding: 0 30px;

    @include screen-xxl {
      padding: 0 15px; }

    @include screen-md {
      padding: 0 10px; }

    @include screen-sm {
      padding: 0;
      width: 100%; }

    &:nth-child(even) {
      margin-top: -208px;

      @include screen-xxl {
        margin-top: -170px; }

      @include screen-md {
        margin-top: 0; } } }

  &__item {
    margin-bottom: 50px;
    position: relative;
    border-radius: 20px;
    overflow: hidden;

    @include screen-xxl {
      margin-bottom: 36px;
      border-radius: 12px; }

    @include screen-md {
      margin-bottom: 24px;
      border-radius: 8px; }

    &::before {
      content: '';
      display: inline-block;
      padding-top: 74.1%; } }

  &__article {
    padding-bottom: 50px;
    width: 50%;
    padding-right: 30px;

    @include screen-xxl {
      padding-right: 15px;
      padding-bottom: 30px; }

    @include screen-md {
      width: 100%;
      padding-right: 0;
      padding-bottom: 36px; }

    @include screen-sm {
      padding-bottom: 16px; }

    h2 {
      @extend .p-title;
      margin-bottom: 40px !important;

      @include screen-xxl {
        margin-bottom: 32px !important; }

      @include screen-md {
        margin-bottom: 32px !important; }

      @include screen-sm {
        margin-bottom: 32px !important; } }

    a {
      @extend .link; }

    p {
      font-weight: 300;
      font-size: 20px;
      line-height: 160%;
      letter-spacing: -0.02em;
      color: #CFCFCF;
      padding-bottom: 30px;

      @include screen-xxl {
        font-size: 18px;
        padding-bottom: 24px; }

      @include screen-md {
        font-size: 16px; } } }

  &__caption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    z-index: 2;

    @include screen-xxl {
      padding: 24px; }

    @include screen-md {
      padding: 20px; }

    @include screen-sm {
      padding: 24px; } }

  &__icon {
    width: 60px;
    height: 60px;
    background: #53F0CE;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include screen-xxl {
      width: 48px;
      height: 48px; }

    @include screen-md {
      width: 30px;
      height: 30px;
      position: relative;
      top: -6px;
      left: -6px; }

    @include screen-sm {
      width: 36px;
      height: 36px; }

    img {
      width: 24px;
      height: 24px;
      object-fit: contain;

      @include screen-xxl {
        width: 20px;
        height: 20px; }

      @include screen-md {
        width: 12px;
        height: 12px; }

      @include screen-sm {
        width: 15px;
        height: 15px; } } }

  &__description {
    font-weight: 500;
    font-size: 22px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #F2F2F2;

    @include screen-xxl {
      font-size: 20px; }

    @include screen-md {
      font-size: 15px; } }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5025) 60.42%, rgba(0, 0, 0, 0.7) 100%); }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; } } }
