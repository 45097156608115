.form {

  &--sm {

    .form {

      &__field {
        padding-bottom: 24px;

        @include screen-md {
          padding-bottom: 16px; } } } }

  &__field {
    padding-bottom: 32px; }

  &__checkbox {
    padding-bottom: 40px;

    @include screen-sm {
      padding-bottom: 32px; } }

  &__submit {
    display: flex;
    justify-content: center;
    padding-top: 8px; }

  &__radio-group {
    padding: 2px 0 10px 0;
    display: flex;
    flex-wrap: wrap;

    @include screen-md {
      padding: 8px 0 12px 0; }

    .wpcf7-form-control-wrap {
      display: flex !important; }

    .wpcf7-form-control {
      display: flex;
      flex-wrap: wrap; }

    .wpcf7-list-item-label {
      @extend .form__radio-btn; }

    .wpcf7-list-item {
      flex-shrink: 0;
      @extend .form__radio; }

    input[type=radio] {
      @extend .form__radio-field;

      &:checked {

        &~.wpcf7-list-item-label {
          background: #53F0CE;
          color: #0C100F; } } } }

  &__radio {
    display: block;
    margin: 0 10px 10px 0 !important;

    @include screen-md {
      margin: 0 8px 8px 0 !important; } }

  &__radio-btn {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    padding: 10px 24px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 30px;
    transition: background 300ms ease, color 300ms ease;

    @include screen-md {
      font-size: 14px; }

    @include screen-sm {
      padding: 10px 20px; }

    body:not(.mobile) &:hover {
      background: #53F0CE;
      color: #0C100F; } }

  &__radio-field {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
    visibility: hidden;

    &:checked {

      &+.form__radio-btn {
        background: #53F0CE;
        color: #0C100F; } } } }

.error-msg {
  font-weight: 400;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #CC3559;
  padding-top: 8px;
  display: none;

  @include screen-md {
    font-size: 13px;
    padding-top: 6px; } }

.error {

  .error-msg {
    display: block; } }

.label {
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: #FFFFFF;

  @include screen-md {
    font-size: 15px;
    padding-bottom: 12px;
    color: #CFCFCF; }

  span {
    font-size: 16px;
    color: #5D716F;
    display: block;

    @include screen-md {
      display: inline;
      font-size: 15px;
      color: #CFCFCF; } }

  &--sm {
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #CFCFCF;
    padding-bottom: 12px;

    @include screen-md {
      font-size: 13px;
      padding-bottom: 8px; } } }

