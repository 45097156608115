.vacancies {
  position: relative;

  &--short {

    .vacancies {

      &__header {
        padding-bottom: 50px;

        @include screen-xxl {
          padding-bottom: 40px; }

        @include screen-md; }

      &__subtitle {
        padding-bottom: 0; } } }

  &--empty {

    .vacancies {

      &__header {
        border: none;
        padding-bottom: 50px; }

      &__subtitle {
        padding-bottom: 0; } } }

  &__empty {
    padding: 100px 60px;
    background: rgba(17, 17, 17, 0.6);
    border: 2px dashed rgba(118, 118, 118, 0.3);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 600ms ease, transform 600ms ease;

    &[data-animated] {
      opacity: 1;
      transform: translateY(0); }

    @include screen-xxl {
      padding: 60px 30px; } }

  &__empty-icon {
    width: 94px;
    height: 94px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.03);
    margin-bottom: 32px;

    @include screen-xxl {
      width: 80px;
      height: 80px;
      margin-bottom: 30px; }

    @include screen-md {
      width: 88px;
      height: 88px;
      margin-bottom: 24px; }

    img {
      display: block;
      width: 50px;
      height: 50px;
      object-fit: contain;

      @include screen-xxl {
        width: 40px;
        height: 40px; }

      @include screen-md {
        width: 48px;
        height: 48px; } } }

  &__empty-title {
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    padding-bottom: 20px;

    @include screen-xxl {
      font-size: 26px;
      padding-bottom: 18px; }

    @include screen-md {
      font-size: 24px;
      padding-bottom: 16px; } }

  &__empty-text {
    font-weight: 300;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #CFCFCF;
    width: 100%;
    max-width: 400px;
    padding-bottom: 50px;

    @include screen-xxl {
      font-size: 18px;
      padding-bottom: 32px; }

    @include screen-md {
      font-size: 16px; }

    a {
      @extend .link; } }

  &__empty-btn {
    width: 100%;
    max-width: 360px;

    @include screen-md {
      max-width: 280px; } }

  &__header {
    position: relative;
    padding-bottom: 38px;
    border-bottom: 1px solid #535353;

    @include screen-xxl {
      padding-bottom: 30px; }

    @include screen-sm {
      padding-bottom: 20px; }

    &[data-animated] {

      .vacancies {

        &__title, &__subtitle, &__tags {
          opacity: 1;
          transform: translateY(0); } } }

    // +screen-sm
    //   margin-left: -20px
    //   margin-right: -20px
 }    //   padding: 0 20px 20px 20px

  &__title {
    font-weight: 500;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #F2F2F2;
    padding-bottom: 8px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 600ms ease, transform 600ms ease;

    @include screen-xxl {
      font-size: 36px; }

    @include screen-md {
      font-size: 30px; } }

  &__subtitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #53F0CE;
    padding-bottom: 40px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 600ms ease, transform 600ms ease;

    @include screen-xxl {
      font-size: 20px;
      padding-bottom: 32px; }

    @include screen-md {
      padding-bottom: 40px; } }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 600ms ease 300ms, transform 600ms ease 300ms;

    @include screen-sm {
      margin-left: -20px;
      margin-right: -20px;
      padding: 0 20px;
      flex-wrap: nowrap;
      overflow-x: auto;
      padding-bottom: 10px; } }

  &__tag {
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    padding: 13px 32px;
    display: flex;
    border: 2px solid #5D646B;
    border-radius: 12px;
    margin-right: 12px;
    margin-bottom: 12px;
    transition: border 300ms ease, background 300ms ease, color 300ms ease;
    white-space: nowrap;
    position: relative;
    flex-shrink: 0;

    @include screen-xxl {
      font-size: 16px;
      padding: 12px 26px; }

    @include screen-md {
      padding: 11px 24px;
      margin-right: 10px;
      margin-bottom: 10px; }

    body:not(.mobile) &:hover, &.active {
      background: #53F0CE;
      color: #0C100F;
      border-color: #53F0CE; }

    &.is-loading {
      pointer-events: none;
      cursor: auto;
      background: #53F0CE;
      border-color: #53F0CE;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 32px;
        height: 32px;
        background: url('../img/btn/spinner.svg') 50% 50% no-repeat;
        background-size: contain;
        z-index: 3;

        @include screen-xxl {
          width: em(36);
          height: em(36); }

        @include screen-md {
          width: 30px;
          height: 30px; } }

      span {
        opacity: 0; } } }

  &__list {
    position: relative;
    padding-bottom: 60px;
    overflow: hidden;

    @include screen-xxl {
      padding-bottom: 40px; } }

  &__item {
    padding: 50px 0;
    display: flex;
    align-items: center;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #535353;
      transform: translateX(-100%);
      transition: transform 600ms ease 300ms; }

    @include screen-xxl {
      padding: 40px 0; }

    @include screen-md {
      padding: 40px 0;
      display: block; }

    &[data-animated] {

      &::before {
        transform: translateX(0); }

      .vacancies {

        &__location, &__name, &__techs {
          opacity: 1;
          transform: translateY(0); }

        &__button {
          opacity: 1; } }

      body:not(.mobile) &:hover {

        .vacancies {

          &__name {
            color: #53F0CE; } }

        .btn--toggle {

          .btn {

            &__name-wrapper {
              display: block;
              max-width: 100%; }

            &__arrow {
              display: none; }

            &__bg {
              opacity: 1;
              transform: translate(-50%, -50%) scale(1.6); }

            &__name {
              color: #0C100F; } } } } } }

  &__item-col {

    &:first-child {
      width: 100%; }

    &:last-child {
      flex-shrink: 0; } }

  &__location {
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    position: relative;
    margin-bottom: 8px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 600ms ease, transform 600ms ease;

    @include screen-xxl {
      font-size: 18px; }

    @include screen-md {
      font-size: 16px; }

    &--hot {
      padding-left: 32px;
      color: #FF9F2F;

      @include screen-xxl {
        padding-left: 28px; }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        background: url('../img/vacancies/icon.png') 50% 50% no-repeat;
        background-size: contain;

        @include screen-xxl {
          width: 22px;
          height: 22px; }

        @include screen-md {
          width: 20px;
          height: 20px; } } } }

  &__name {
    font-weight: 300;
    font-size: 40px;
    line-height: 110%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #F2F2F2;
    margin-bottom: 32px;
    display: inline-block;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 600ms ease 300ms, transform 600ms ease 300ms, color 300ms ease;

    @include screen-xxl {
      font-size: 36px;
      margin-bottom: 24px; }

    @include screen-md {
      font-size: 30px;
      margin-bottom: 32px; } }

  &__techs {
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 600ms ease 450ms, transform 600ms ease 450ms;

    @include screen-md {
      padding-bottom: 24px; } }

  &__tech {
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    padding: 7px 26px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 30px;
    margin-right: 12px;
    margin-bottom: 12px;
    flex-shrink: 0;

    @include screen-xxl {
      font-size: 15px; }

    @include screen-md {
      padding: 7px 20px;
      margin-right: 8px;
      margin-bottom: 8px; } }

  &__button {
    opacity: 0;
    transition: opacity 600ms ease 600ms, transform 600ms ease 600ms; }

  &__more-wrapper {
    display: flex;
    justify-content: center;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 600ms ease, transform 600ms ease;

    &[data-animated] {
      opacity: 1;
      transform: translateY(0); } }

  &__more-btn {
    width: 100%;
    max-width: 466px;
    display: flex;
    justify-content: center;

    @include screen-xxl {
      max-width: 400px; }

    @include screen-md {
      max-width: 335px; } } }

