.gallery {
  position: relative;

  &--lg {

    .yt-video__container::before {
      @include screen-sm {
        padding-top: 100%; } }

    &.is-video-active-slide {

      .gallery {

        &__btn {

          &--prev {
            right: auto;
            left: -45vw;

            @include screen-md {
              left: 12px;
              right: auto; } }

          &--next {
            left: auto;
            right: -45vw;

            @include screen-md {
              right: 12px;
              left: auto; } } } } }

    .gallery {

      &__btn {
        display: block;
        width: 50vw;

        @include screen-md {
          display: block;
          width: 32px; }

        &--prev {
          right: 50%;
          left: auto;

          @include screen-md {
            left: 12px;
            right: auto; } }

        &--next {
          right: auto;
          left: 50%;

          @include screen-md {
            right: 12px;
            left: auto; } } }

      &__header {
        margin: 70px 0;

        @include screen-xxl {
          margin: 32px 0; } }

      &__title {
        font-weight: 500;
        text-transform: none;
        font-size: 40px;
        line-height: 120%;

        @include screen-xxl {
          font-size: 32px; }

        @include screen-md {
          font-size: 28px; } }

      &__container {
        overflow: visible;
        padding-bottom: 0 !important;

        @include screen-sm {
          margin: 0; } }

      &__item {
        border-radius: 20px;
        overflow: hidden;

        @include screen-sm {
          &::before {
            padding-top: 100%; } }

        &.swiper-slide-active {}

        &.swiper-slide-next,
        &.swiper-slide-prev {

          &::after {
            opacity: 1; } }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
          opacity: 0;
          transition: opacity 300ms ease; } } } }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0;
    overflow: hidden;

    @include screen-xxl {
      margin: 24px 0; }

    @include screen-md {
      margin: 32px 0; } }

  &__title {
    font-weight: 400;
    font-size: 28px;
    line-height: 120%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #F2F2F2;
    overflow: hidden;

    @include screen-xxl {
      font-size: 22px; }

    @include screen-md {
      font-size: 18px; } }

  &__navigation {}

  &__container {
    padding-bottom: 32px;

    @include screen-xxl {
      padding-bottom: 24px; }

    @include screen-md {
      padding-bottom: 20px; }

    @include screen-sm {
      width: auto;
      margin-left: -20px;
      margin-right: -20px; } }

  &__item {
    position: relative;

    &::before {
      content: '';
      display: inline-block;
      padding-top: 56%; }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      padding: 0 !important;
      margin: 0 !important; } }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }

  &__counter {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #767676;

    strong {
      font-weight: 500;
      font-size: 34px;
      line-height: 41px;
      color: #f2f2f2; }

    @include screen-xxl {
      font-size: 18px;
      line-height: 22px;

      strong {
        font-size: 24px;
        line-height: 31px; } }

    @include screen-md {
      font-size: 16px;
      line-height: 20px;

      strong {
        font-size: 22px;
        line-height: 26px; } } }

  &__description {
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #767676;

    @include screen-xxl {
      font-size: 18px; }

    @include screen-md {
      font-size: 14px; } }

  &__btn {
    display: block;
    width: 50%;
    height: 100%;
    opacity: 0.6;
    transition: opacity 300ms ease;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;

    @include screen-md {
      display: block;
      width: 32px;
      height: 32px;
      opacity: 1; }

    &.swiper-button-lock {
      display: none !important; }

    body:not(.mobile) &:hover {
      opacity: 1; }

    svg {
      display: none;
      width: 100%;
      height: 100%;
      stroke: #fff;

      @include screen-md {
        display: block; } }

    &--prev {
      left: 0;

      @include screen-md {
        left: 12px; } }

    &--next {
      right: 0;

      @include screen-md {
        right: 12px; } } } }
