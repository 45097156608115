.article {
  position: relative;

  &__header {
    position: relative;
    margin-bottom: 78px;

    @include screen-xxl {
      margin-bottom: 60px; }

    @include screen-md {
      margin-bottom: 50px; }

    @include screen-sm {
      margin-bottom: 40px; } }

  &__header-caption {
    padding-top: 30vw;
    padding-bottom: 60px;
    position: relative;

    @media only screen and (min-width: 1920px) {
      padding-top: 576px; }

    @include screen-xxl {
      padding-bottom: 40px; }

    @include screen-md {
      padding-bottom: 32px;
      padding-top: 38vw; }

    @include screen-sm {
      padding-top: 70vw; } }

  &__options {
    position: relative; }

  &__aside {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 28px;
    padding-bottom: 200px;

    @include screen-xxl {
      padding-bottom: 150px; }

    @include screen-md {
      padding-bottom: 0; }

    @include screen-sm {
      display: block;
      padding: 0;
      margin-left: -20px;
      align-items: flex-start; } }

  &__backlink {
    margin-bottom: 55px;

    @include screen-sm {
      margin: 0; } }

  &__share {

    @include screen-xxl;

    @include screen-md {
      position: relative;
      top: auto;
      left: auto;
      justify-content: center; }

    &--sticky {
      position: sticky;
      top: 150px; }

    &--desktop {
      display: block;

      @include screen-md {
        display: none; } }

    &--mobile {
      display: none;

      @include screen-md {
        display: flex; } } }

  &__hero {
    width: 100%;
    position: relative;
    margin-bottom: 60px;

    @include screen-xxl {
      margin-bottom: 50px; }

    @include screen-md {
      margin-bottom: 32px; }

    @include screen-sm {
      width: auto;
      margin-left: -20px;
      margin-right: -20px; }

    &::before {
      content: '';
      display: block;
      padding-top: 56%;

      @include screen-sm {
        padding-top: 86%; } }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__title {
    font-weight: 500;
    font-size: 72px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #fff;
    padding-bottom: 50px;

    @include screen-xxl {
      font-size: 56px;
      padding-bottom: 40px; }

    @include screen-xl {
      font-size: 48px; }

    @include screen-md {
      font-size: 36px;
      padding-bottom: 20px; } }

  &__date {
    font-family: $bebas;
    font-size: 32px;
    line-height: 100%;
    color: #FFFFFF;
    text-align: center;

    @include screen-xxl {
      font-size: 28px; }

    @include screen-md {
      font-size: 24px;
      padding-bottom: 24px; }

    span {
      color: #CFCFCF;
      opacity: 0.4; } }

  &__content {
    margin-bottom: 50px;

    @include screen-xxl {
      margin-bottom: 50px; }

    @include screen-md {
      margin-bottom: 0; } }

  &__gallery {
    padding: 20px 0 70px 0;

    @include screen-xxl {
      padding: 16px 0 40px 0; }

    @include screen-md {
      padding: 5px 0 60px 0; } }

  &__bottom {}

  &__bottom-title {
    font-weight: 500;
    font-size: 40px;
    line-height: 80%;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    padding-bottom: 80px;

    @include screen-xxl {
      font-size: 36px;
      padding-bottom: 60px; }

    @include screen-md {
      font-size: 32px;
      line-height: 110%;
      padding-bottom: 32px; } }

  &__bottom-list {
    position: relative; }

  &__bottom-row {
    display: flex;
    margin-left: -45px;
    margin-right: -45px;

    @include screen-xxl {
      margin-left: -30px;
      margin-right: -30px; }

    @include screen-xl {
      margin-left: -20px;
      margin-right: -20px; }

    @include screen-sm {
      margin: 0;
      flex-wrap: wrap; } }

  &__bottom-col {
    width: calc(100% / 3);
    padding: 0 45px 0 45px;

    @include screen-xxl {
      padding: 0 30px 0 30px; }

    @include screen-xl {
      padding: 0 20px 0 20px; }

    @include screen-md {
      width: calc(100% / 2);

      &:nth-child(3) {
        display: none; } }

    @include screen-sm {
      width: 100%;
      padding: 0 0 40px 0;

      &:last-child {
        padding-bottom: 0; }

      &:nth-child(3) {
        display: block; } } } }


.article-content {

  &--simple {

    h2, h3, p {
      padding-top: 0 !important; } }

  p {
    font-size: 20px;
    line-height: 32px;
    font-weight: 300;
    letter-spacing: -0.02em;
    color: #CFCFCF;
    padding-bottom: 32px;

    @include screen-xxl {
      font-size: 18px;
      line-height: 26px;
      padding-bottom: 28px; }

    @include screen-md {
      font-size: 16px;
      line-height: 26px;
      padding-bottom: 26px; }

    &:first-child {

      &::first-letter {
        font-size: 40px;

        @include screen-xxl {
          font-size: 32px; }

        @include screen-md {
          font-size: 28px; } } }

    strong {
      font-weight: 500;
      color: #F7F7F7; } }

  a {
    color: #53F0CE;
    text-decoration: underline;
    text-underline-offset: 4px;

    body:not(.mobile) &:hover {
      text-decoration: none; } }

  img {
    padding: 35px 0;
    width: 100%;
    height: auto;
    display: block;

    @include screen-xxl {
      padding: 30px 0; }

    @include screen-md {
      padding: 25px 0; }

    @include screen-sm {
      padding: 20px 0 20px 0; }

    &+p {
      padding-top: 35px;

      @include screen-xxl {
        padding-top: 25px; }

      @include screen-md {
        padding-top: 25px; }

      @include screen-sm {
        padding-top: 12px; } } }

  .image {

    img {
      padding: 0 !important; }

    &--rounded {

      img {
        border-radius: 20px;

        @include screen-xxl {
          border-radius: 16px; }

        @include screen-md {
          border-radius: 12px; } } }

    &--to-right {
      padding-left: 80px;

      @include screen-lg {
        padding-left: 0; }

      @include screen-md {
        padding-left: 0;
        padding-top: 34px; }

      @include screen-sm {
        padding-top: 6px; } } }

  .media-block, figure.wp-block-image {
    padding: 35px 0;
    margin: 0 !important;

    @include screen-xxl {
      padding: 30px 0; }

    @include screen-md {
      padding: 25px 0; }

    @include screen-sm {
      padding: 20px 0; }

    &__yt {
      margin-bottom: 24px;

      @include screen-md {
        margin-bottom: 20px; } }

    &__video {
      position: relative;
      margin-bottom: 24px;

      @include screen-md {
        margin-bottom: 20px; }

      &::before {
        content: '';
        display: inline-block;
        padding-top: 56%; } }

    video, iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain; }

    img {
      padding: 0 0 24px 0;

      @include screen-md {
        padding: 0 0 20px 0; } }

    span, figcaption.wp-element-caption {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: -0.02em;
      color: #767676;

      @include screen-md {
        font-size: 13px; } } }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 5px 0;

    li {
      font-size: 20px;
      line-height: 32px;
      font-weight: 300;
      letter-spacing: -0.02em;
      color: #CFCFCF;
      margin-bottom: 13px;
      padding-left: 30px;
      position: relative;

      @include screen-xxl {
        font-size: 18px;
        line-height: 26px; }

      @include screen-md {
        font-size: 16px;
        line-height: 26px; }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-60%);
        width: 13px;
        height: 13px;
        background: url('../img/article/ul-point.svg') 50% 50% no-repeat;
        background-size: contain; } } }

  ol {
    list-style-type: none;
    counter-reset: list;
    margin: 0;
    padding: 5px 0;

    li {
      font-size: 20px;
      line-height: 32px;
      font-weight: 300;
      letter-spacing: -0.02em;
      color: #CFCFCF;
      position: relative;
      margin-bottom: 13px;
      padding-left: 48px;

      @include screen-xxl {
        font-size: 18px;
        line-height: 26px; }

      @include screen-md {
        font-size: 16px;
        line-height: 26px; }

      &::before {
        counter-increment: list;
        content: counter(list);
        width: 28px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        font-weight: 500;
        font-size: 17px;
        letter-spacing: -0.02em;
        color: #15181B;
        background: #53F0CE;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%); } } }

  ul, ol {
    &+p {
      padding-top: 35px;

      @include screen-xxl {
        padding-top: 25px; }

      @include screen-md {
        padding-top: 25px; }

      @include screen-sm {
        padding-top: 12px; } } } }
