.article-content > .wp-block-quote {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 35px 0 35px 0;

  @include screen-xxl {
    padding: 25px 0; }

  @include screen-md {
    padding: 25px 0 25px 0; }

  @include screen-sm {
    padding: 100px 0 20px 0; }

  &+p {
    padding-top: 35px;

    @include screen-xxl {
      padding-top: 25px; }

    @include screen-md {
      padding-top: 25px; }

    @include screen-sm {
      padding-top: 12px; } }

  &::before {
    content: '';
    position: absolute;
    top: 35px;
    left: 0;
    width: 64px;
    height: 64px;
    background: url('../img/blockquote/icon.svg') 50% 50% no-repeat;
    background-size: contain;

    @include screen-xxl {
      width: 60px;
      height: 60px;
      top: 25px; }

    @include screen-md {
      width: 56px;
      height: 56px;
      top: 25px;

      @include screen-md {
        top: 20px; } } }

  a {
    @extend .link; }

  p {
    // font-weight: 300
    // font-size: 32px
    // line-height: 140%
    // letter-spacing: -0.02em
    // color: #F2F2F2
    // margin-left: 112px
    // padding-bottom: 0 !important

    // +screen-xxl
    //   font-size: 26px
    //   margin-left: 98px

    // +screen-md
    //   font-size: 22px
    //   line-height: 150%
    //   margin-left: 80px

    // +screen-sm
    //   margin-left: 0

    // &:first-child::first-letter
    //   font-size: inherit !important

    font-size: 20px;
    line-height: 32px;
    font-weight: 300;
    letter-spacing: -0.02em;
    color: #CFCFCF;
    margin-left: 112px;
    padding-bottom: 0 !important;
    margin-top: 32px;

    &:first-child {
      margin-top: 0 !important; }

    @include screen-xxl {
      font-size: 18px;
      line-height: 26px;
      margin-left: 98px;
      margin-top: 28px; }

    @include screen-md {
      font-size: 16px;
      line-height: 26px;
      margin-left: 80px;
      margin-top: 26px; }

    @include screen-sm {
      margin-left: 0; }

    &:first-child::first-letter {
      font-size: inherit !important; }

    strong {
      font-weight: 500;
      color: #F7F7F7; } }

  p.quote-author {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    position: relative;
    padding-left: 152px;
    margin-left: 112px;
    font-style: normal;
    margin-top: 40px;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;

    @include screen-xxl {
      margin-top: 32px; }

    @include screen-md {
      font-size: 15px;
      padding-left: 80px;
      margin-left: 0;
      margin-top: 24px; }

    @include screen-sm {
      padding-left: 64px; }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 120px;
      height: 1px;
      background: #FF9F2F;

      @include screen-md {
        height: 2px;
        width: 48px; }

      @include screen-sm {
        width: 40px; } } } }

