.benefits {
  display: flex;
  align-items: stretch;
  margin-left: em(-30);
  margin-right: em(-30);

  @include screen-md {
    margin-left: -18px;
    margin-right: -18px; }

  @include screen-sm {
    flex-wrap: wrap;
    margin-left: -30px;
    margin-right: -30px;
    padding: 0 36px; }

  &.active {

    .benefits {

      &__value-text {
        transform: translateY(0); }

      &__description {
        opacity: 1;
        transform: translateY(0); }

      &__wrapper {

        &::before {
          transform: translateX(0); } }

      &__item {

        &:nth-child(1) {

          .benefits {

            &__value-text {
              transition-delay: 600ms; }

            &__description {
              transition-delay: 300ms; }

            &__wrapper {

              &::before {
                transition-delay: 0ms; } } } }

        &:nth-child(2) {

          .benefits {

            &__value-text {
              transition-delay: 900ms; }

            &__description {
              transition-delay: 600ms; }

            &__wrapper {

              &::before {
                transition-delay: 300ms; } } } }

        &:nth-child(3) {

          .benefits {

            &__value-text {
              transition-delay: 1200ms; }

            &__description {
              transition-delay: 900ms; }

            &__wrapper {

              &::before {
                transition-delay: 600ms; } } } }

        &:nth-child(4) {

          .benefits {

            &__value-text {
              transition-delay: 1500ms; }

            &__description {
              transition-delay: 1200ms; }

            &__wrapper {

              &::before {
                transition-delay: 900ms; } } } } } } }

  &__item {
    min-height: 100%;
    padding: 0 em(30);
    display: flex;

    @include screen-md {
      width: 25%;
      padding: 0 18px; }

    @include screen-sm {
      width: 50%;
      padding: 0 30px;
      margin-bottom: 32px; }

    &:nth-child(4) {

      .benefits {

        &__value-text {
          transition-delay: 600ms; }

        &__description {
          transition-delay: 300ms; }

        &__wrapper {

          &::before {
            transition-delay: 0ms; } } } }

    &:nth-child(3) {

      .benefits {

        &__value-text {
          transition-delay: 900ms; }

        &__description {
          transition-delay: 600ms; }

        &__wrapper {

          &::before {
            transition-delay: 300ms; } } } }

    &:nth-child(2) {

      .benefits {

        &__value-text {
          transition-delay: 1200ms; }

        &__description {
          transition-delay: 900ms; }

        &__wrapper {

          &::before {
            transition-delay: 600ms; } } } }

    &:nth-child(1) {

      .benefits {

        &__value-text {
          transition-delay: 1500ms; }

        &__description {
          transition-delay: 1200ms; }

        &__wrapper {

          &::before {
            transition-delay: 900ms; } } } } }

  &__wrapper {
    max-width: em(119);
    padding-bottom: em(24);
    position: relative;
    flex: 1;
    overflow: hidden;

    @include screen-md {
      max-width: none;
      padding-bottom: 12px; }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: #CFCFCF;
      opacity: .2;
      transform: translateX(-100%);
      transition: transform 600ms ease; } }

  &__value {
    margin-bottom: em(8);
    overflow: hidden;

    @include screen-md {
      margin-bottom: 8px; } }

  &__value-text {
    font-family: $bebas;
    text-transform: uppercase;
    font-size: em(80);
    line-height: 100%;
    letter-spacing: -0.05em;
    color: #53F0CE;
    display: inline-block;
    transform: translateY(105%);
    transition: transform 600ms ease;

    @include screen-md {
      font-size: 50px; } }

  &__description {
    font-weight: 400;
    font-size: em(14);
    line-height: 150%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #CFCFCF;
    opacity: 0;
    transform: translateY(10px);
    transition: transform 600ms ease, opacity 600ms ease;

    @include screen-md {
      font-size: 12px;
      line-height: 18px;
      min-height: 36px; } } }
