.history {
  overflow: hidden;

  &__wrapper {}

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 109px;

    @include screen-xxl {
      padding-bottom: 60px; }

    @include screen-md {
      padding-bottom: 55px; } }

  &__title {
    font-weight: 500;
    font-size: 72px;
    line-height: 110%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    width: 100%;
    max-width: 600px;

    @include screen-xxl {
      font-size: 60px;
      max-width: 500px; }

    @include screen-md {
      font-size: 36px;
      line-height: 120%;
      max-width: 300px; } }

  &__controls {}

  &__slider {
    overflow: visible; }

  &__line {
    overflow: hidden; }

  &__line-inner {
    width: 100%;
    height: 1px;
    background: #D9D9D9;
    opacity: 0.4;
    transform: translateX(-100%);
    transition: transform 600ms ease;

    &[data-animated] {
      transform: translateX(0); } }

  &__item {
    width: auto !important;

    &:not(.swiper-slide-visible) {

      .history {

        &__year, &__description {
          opacity: .4;

          @include screen-md {
            opacity: 1; } } } } }

  &__item-wrapper {
    padding-top: 100px;
    width: 223px;
    position: relative;
    padding-bottom: 70px;

    @include screen-xxl {
      padding-top: 64px;
      width: 190px; }

    @include screen-md {
      padding-top: 55px;
      width: 180px; }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 9px;
      height: 9px;
      transform: translateY(-50%);
      border-radius: 50%;
      background: #d9d9d9; } }

  &__year {
    font-family: $bebas;
    text-transform: uppercase;
    font-size: 60px;
    line-height: 100%;
    color: #FFFFFF;
    margin-bottom: 32px;
    opacity: 1;
    transition: opacity 300ms ease;

    @include screen-xxl {
      font-size: 50px;
      margin-bottom: 24px; }

    @include screen-md {
      margin-bottom: 16px; } }

  &__description {
    font-weight: 300;
    font-size: 20px;
    line-height: 145%;
    letter-spacing: -0.02em;
    color: #CFCFCF;
    opacity: 1;
    transition: opacity 300ms ease;

    @include screen-xxl {
      font-size: 18px; }

    @include screen-md {
      font-size: 16px; } }

  a {
    @extend .link; } }
