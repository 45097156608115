.partners-list {
  position: relative;

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -25px;
    margin-right: -25px;

    @include screen-sm {
      margin-left: -16px;
      margin-right: -16px; } }

  &__item {
    padding: 0 25px 60px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      width: 20%; }

    @include screen-xxl {
      padding: 0 25px 30px 25px; }

    @include screen-md {
      width: 33.33333% !important;
      padding: 0 25px 50px 25px; }

    @include screen-sm {
      width: 50% !important;
      padding: 0 16px 40px 16px; }

    img {
      display: block;
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;

      @include screen-md {
        max-height: 70px; } } } }
