.partners-slider {

  &__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    margin-bottom: 100px;
    overflow: hidden;

    @include screen-xxl {
      font-size: 32px;
      margin-bottom: 60px; }

    @include screen-md {
      font-size: 28px;
      margin-bottom: 80px; }

    @include screen-sm {
      text-align: center; } }

  &__container {
    opacity: 0;
    transition: opacity 600ms ease 300ms;

    &[data-animated] {
      opacity: 1;

      .partners-slider {

        &__pagination {
          opacity: 1; } } }

    @include screen-sm {
      margin-left: -20px;
      margin-right: -20px; } }

  &__item {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      object-fit: contain;
      max-width: 100%;
      max-height: 100%; } }

  &__pagination {
    opacity: 0;
    transition: opacity 600ms ease; } }
