.m-navigation {
  display: none;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  padding-right: em(75);
  z-index: 3;
  transform: translateZ(0);
  opacity: 0;
  transition: opacity 600ms ease 1.5s;

  &[data-animated] {
    opacity: 1; }

  @include screen-md {
    display: none !important; }

  &__wrapper {
    position: relative;
    padding-bottom: em(14); }

  &__header {
    position: relative;
    padding-bottom: em(142);
    margin-bottom: em(12);

    &::before {
      content: '';
      position: absolute;
      top: em(82);
      left: 50%;
      transform: translateX(-50%);
      height: em(120);
      width: 2px;
      background: #fff;
      opacity: 0.1; } }

  &__value {
    font-family: $bebas;
    text-transform: uppercase;
    font-size: em(60);
    line-height: 100%;
    letter-spacing: -0.05em;
    color: #FFFFFF;
    position: relative; }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center; }

  &__item {
    padding: em(10);
    flex-shrink: 0;

    span {
      display: block;
      width: em(12);
      height: em(12);
      border: 2px solid transparent;
      border-radius: 50%;
      position: relative;
      transition: border 300ms ease;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background: rgba(217, 217, 217, 0.2);
        transition: background 300ms ease; } }

    &:last-child {
      margin-bottom: 0; }

    &.active {

      span {
        border-color: #53F0CE;

        &::before {
          background: transparent; } } } } }
