.subscribe {
  display: flex;
  padding-bottom: 16px;

  &:last-child {
    padding-bottom: 0; }

  @include screen-xxl {
    flex-direction: row; }

  @include screen-lg {
    flex-direction: row; }

  &__field-wrapper {
    width: 265px;
    margin-right: 16px;
    position: relative;

    @include screen-xxl {
      width: 255px;
      margin-right: 16px; }

    @include screen-lg {
      width: 351px;
      margin-right: 16px;
      margin-bottom: 0; }

    @media only screen and (max-width: 1199px) {
      width: 288px; }

    @include screen-md {
      width: 220px; }

    @include screen-sm {
      width: 100%; }

    &::before {
      content: '';
      position: absolute;
      top: 12px;
      right: 12px;
      width: 40px;
      height: 40px;
      background: url('../img/subscribe/error-icon.svg') 50% 50% no-repeat;
      background-size: contain;
      opacity: 0;
      visibility: hidden;
      transition: all 300ms ease;

      @include screen-sm {
        width: 32px;
        height: 32px;
        top: 8px;
        right: 8px; } }

    &.error {

      &::before {
        opacity: 1;
        visibility: visible; }

      .subscribe {

        &__field {
          border-color: #CC3559; } } }

    .error-msg {
      padding-left: 24px;

      @include screen-sm {
        padding-left: 16px; } } }

  &__field {
    border: 2px solid #53F0CE;
    border-radius: 70px;
    background: none;
    height: 64px;
    line-height: 64px;
    padding: 0 30px;
    box-shadow: none;
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #fff;
    width: 100%;
    position: relative;

    @include screen-xl {
      height: 56px;
      line-height: 56px;
      border-radius: 60px;
      font-size: 16px; }

    @include screen-lg {
      height: 64px;
      line-height: 64px;
      font-size: 18px; }

    @include screen-md {
      height: 48px;
      line-height: 48px;
      font-size: 17px;
      padding: 0 30px; }

    @include screen-sm {
      height: 48px;
      line-height: 48px;
      font-size: 17px;
      padding: 0 30px; }

    &::placeholder {
      color: #FFFFFF;
      opacity: .4;
      font-weight: 400; } }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: none;
    background: #53F0CE;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    transition: background 300ms ease;
    position: relative;

    @include screen-xxl {
      width: 64px; }

    @include screen-xl {
      width: 56px;
      height: 56px; }

    @include screen-lg {
      width: 64px;
      height: 64px;
      flex-shrink: 0; }

    @include screen-md {
      width: 48px;
      height: 48px; }

    @include screen-sm {
      width: 48px;
      height: 48px; }

    &.is-loading {

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
        background: url('../img/btn/spinner.svg') 50% 50% no-repeat;
        background-size: contain;

        @include screen-md {
          width: 24px;
          height: 24px; } }

      svg {
        display: none; } }

    body:not(.mobile) &:hover {
      background: #fff; }

    svg {
      display: block;
      width: 24px;
      height: 24px;
      stroke: #0C100F; }

    span {
      display: none;
      font-weight: 600;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: -0.02em;
      color: #0C100F;
      padding-left: 16px; } } }
