.techs {

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px; }

  &__col {
    width: 50%;
    padding: 0 10px;

    @include screen-md {
      width: 100%; } }

  &__item {
    padding-bottom: 80px;
    display: flex;

    @include screen-xxl {
      padding-bottom: 40px; }

    @include screen-sm {
      flex-direction: column; }

    body:not(.mobile) &:hover {

      .techs {

        &__icon {
          transform: scale(1.05);

          &::before {
            opacity: 1; } }

        &__description {
          color: #a0a0a0; } } }

    &[data-animated] {

      .techs {

        &__icon {
          opacity: 1; }

        &__title span {
          transform: translateY(0); }

        &__description {
          transform: translateY(0);
          opacity: 1; } } } }

  &__icon {
    width: 72px;
    height: 72px;
    flex-shrink: 0;
    border-radius: 50%;
    background: linear-gradient(135deg, #9EFFEA 0%, #2EE2BC 100%);
    margin-right: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    opacity: 0;
    transition: opacity 600ms ease, transform 300ms ease;

    @include screen-xxl {
      width: 60px;
      height: 60px;
      margin-right: 24px; }

    @include screen-md {
      width: 72px;
      margin-right: 32px;
      height: 72px; }

    @include screen-sm {
      margin-right: 0;
      margin-bottom: 32px; }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      opacity: 0;
      transform: opacity 600ms ease; }

    svg {
      position: relative;
      z-index: 2;
      display: block;
      width: 32px;
      height: 32px;
      fill: #0C100F;
      transition: fill 300ms ease;

      @include screen-xxl {
        width: 28px;
        height: 28px; }

      @include screen-md {
        width: 32px;
        height: 32px; } } }

  &__caption {
    width: 100%;
    max-width: 363px;

    @include screen-xxl {
      max-width: 320px; }

    @include screen-md {
      max-width: none; } }

  &__title {
    font-weight: 500;
    font-size: 22px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    margin-bottom: 12px;
    overflow: hidden;

    span {
      display: inline-block;
      transform: translateY(105%);
      transition: transform 600ms ease 300ms; }

    @include screen-xxl {
      font-size: 20px; }

    @include screen-md {
      font-size: 22px; } }

  &__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #767676;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 600ms ease 450ms, transform 600ms ease 450ms, color 300ms ease;

    @include screen-xxl {
      font-size: 15px; }

    @include screen-md {
      font-size: 16px; }

    a {
      @extend .link; } } }
