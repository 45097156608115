.counter {
  position: relative;
  padding: em(55) em(125) em(55) 0;

  @include screen-md {
    padding: 38px 82px 42px 0; }

  @include screen-sm {
    padding: 27px 58px 29px 0; }

  &__text {
    font-family: $bebas;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: em(50);
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #F0F0F0;
    opacity: 0.3;
    transform: translateY(-20px);
    opacity: 0;
    transition: opacity 600ms ease 1.6s, transform 600ms ease 1.6s;

    @include screen-md {
      font-size: 32px; }

    @include screen-sm {
      font-size: 22px; }

    &--sm {
      font-family: $rubik;
      font-weight: 500;
      font-size: em(16);
      line-height: 80%;
      letter-spacing: -0.03em;
      text-transform: uppercase;
      color: #FF9F2F;
      opacity: 1;
      transition-delay: 1.9s;

      @include screen-md {
        font-size: 10px; }

      @include screen-sm {
        font-size: 7px; } } }

  &__box {
    width: em(159);
    height: em(159);
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;

    @include screen-md {
      width: 102px;
      height: 102px; }

    @include screen-sm {
      width: 72px;
      height: 72px; } }

  &__circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    @include screen-md {
      left: 2px; }

    svg {
      display: block;
      width: 100%;
      height: 100%;

      path {
        stroke-dasharray: 409;
        stroke-dashoffset: 409;
        transition: all 600ms ease 1.6s;

        @include screen-md {
          stroke-width: 3; } } } }

  &__caption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 0;
    transform: translateX(-20px);
    transition: opacity 600ms ease 1.6s, transform 600ms ease 1.6s; }

  &__value {
    font-family: $bebas;
    text-transform: uppercase;
    font-size: em(80);
    line-height: 100%;
    letter-spacing: -0.05em;
    color: #F0F0F0;

    @include screen-md {
      font-size: 52px; }

    @include screen-sm {
      font-size: 36px; } } }

[data-animated] {

  .counter {

    &__text {
      opacity: 0.3;
      transform: translateY(0);

      &--sm {
        opacity: 1; } }

    &__caption {
      opacity: 1;
      transform: translateY(0); }

    &__circle svg path {
      stroke-dasharray: 409;
      stroke-dashoffset: 0; } } }
