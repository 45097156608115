.radio {
  position: relative;

  .wpcf7-form-control-wrap {
    position: static !important; }

  .wpcf7-list-item {
    display: flex; }

  &.error {

    .radio__box, .wpcf7-list-item-label {
      border-color: #CC3559; } }

  &.disabled {
    pointer-events: none;

    .radio__box, .wpcf7-list-item-label {
      background: #E9E9E9;
      border-color: #E9E9E9; }

    .radio {

      &__name {
        color: #C8C8C8; } } }

  &__label {
    cursor: pointer;
    display: flex;
    align-items: center;

    body:not(.mobile) &:hover {

      .radio__box, .wpcf7-list-item-label {
        background: #04D6A9;
        border-color: #04D6A9; } } }

  input {
    opacity: 0;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;

    &:checked {

      &+ {
        .radio__box, .wpcf7-list-item-label {
          background: #04D6A9;
          border-color: #04D6A9;

          &::before {
            opacity: 1; } } } } }

  &__box, .wpcf7-list-item-label {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 20px;
    background: none;
    border: 1px solid #04D6A9;
    border-radius: 4px;
    transition: background 300ms ease, border 300ms ease;
    position: relative;
    font-size: 0 !important;
    border-radius: 50%;

    @include screen-md {
      margin-right: 14px; }

    &::before {
      content: '';
      width: 12px;
      height: 9px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url('../img/checkbox/icon.svg') 50% 50% no-repeat;
      background-size: contain;
      opacity: 0;
      transition: opacity 300ms ease; }

    &::after {
      display: none !important; } }

  &__description {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #CFCFCF;

    @include screen-md {
      font-size: 14px; }

    a {
      text-decoration: none;
      color: #04D6A9; } }

  .error-msg {
    padding-left: 40px;

    @include screen-md {
      padding-left: 34px; } } }

.wpcf7-form {

  .radio__box {
    display: none !important; } }
