.get-in-touch {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  bottom: 56px;
  right: 56px;
  width: 120px;
  height: 120px;
  transition: transform 300ms ease, bottom 300ms ease;

  &.shift-top {
    bottom: 140px; }

  @include screen-xxl {
    width: 90px;
    height: 90px;
    right: 40px;
    bottom: 40px; }

  @include screen-md {
    width: 72px;
    height: 72px;

    &.shift-top {
      bottom: 100px; } }

  @include screen-sm {
    right: 20px;
    bottom: 20px; }

  body:not(.mobile) &:hover {
    transform: scale(1.05); }

  &__text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../img/get-in-touch/text.svg') 50% 50% no-repeat;
    background-size: contain;

    animation: rotate 16s linear infinite; }

  &__icon {
    display: block;
    width: 48px;
    height: 48px;
    background: url('../img/get-in-touch/icon.svg') 50% 50% no-repeat;
    background-size: contain;

    @include screen-xxl {
      width: 32px;
      height: 32px; }

    @include screen-md {
      width: 30px;
      height: 30px; } } }
