.tabs {

  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #DADFE3; }

  &__buttons {
    display: flex;
    position: relative; }

  &__button {
    display: block;
    padding: 36px 34px;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #90979C;
    font-weight: normal;
    font-family: sans-serif;
    transition: color 300ms ease;

    &.active, body:not(.mobile) &:hover {
      color: #181818; } }

  &__line {
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #EF8937;
    transition: width 300ms ease, transform 300ms ease; }

  &__body {
    position: relative;
    padding: 80px 0 0 0;

    @include screen-md {
      padding: 40px 0 0 0; } }

  &__item {
    display: none;

    &.active {
      display: block; } } }
