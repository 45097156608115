.swu {
  position: relative;
  overflow: hidden;

  &__container {
    width: 100%;
    max-width: 954px;
    margin: 0 auto; }

  &__header {
    position: relative; }

  &__header-wrapper {
    position: relative;
    padding-bottom: 80px;

    @include screen-xxl {
      padding-bottom: 60px; }

    @include screen-sm {
      padding-bottom: 40px; }

    &[data-animated] {

      &::before {
        opacity: 1; } }

    &::before {
      content: '';
      position: absolute;
      top: 110px;
      left: -570px;
      width: 466px;
      height: 776px;
      background: url('../img/swu/icon.svg') 50% 50% no-repeat;
      background-size: contain;
      opacity: 0;
      transition: opacity 600ms ease 600ms;

      @include screen-xxl {
        width: 326px;
        height: 543px;
        top: 100px;
        left: -430px; }

      @include screen-md {
        display: none; } }

    &--inner {
      padding-bottom: 100px;
      margin-bottom: 30px;
      border-bottom: 2px solid rgba(#CFCFCF, .2);

      @include screen-xxl {
        padding-bottom: 60px;
        margin-bottom: 28px; }

      @include screen-md {
        padding-bottom: 70px;
        margin-bottom: 38px; }

      @include screen-sm {
        padding-bottom: 46px;
        margin-bottom: 28px; } } }

  &__backlink {
    position: absolute;
    top: 0;
    left: -122px;

    @include screen-md {
      left: -70px; }

    @include screen-sm {
      top: 0;
      left: 0;
      position: relative;
      margin-bottom: 24px; } }

  &__header-caption {
    display: flex;
    position: relative;
    min-height: 350px;
    margin-left: -10px;
    margin-right: -10px;

    @include screen-lg {
      flex-direction: column-reverse;
      align-items: center; } }

  &__header-col {
    width: 100%;
    position: relative;
    padding: 0 10px;

    @include screen-lg {
      width: 100%; } }

  &__title {
    padding-bottom: 70px;
    font-weight: 500;
    font-size: 80px;
    line-height: 110%;
    display: flex;
    flex-direction: column;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #fff;

    @include screen-xxl {
      font-size: 64px;
      padding-bottom: 60px; }

    @include screen-lg {
      padding-bottom: 10px; }

    @include screen-md {
      font-size: 40px;
      line-height: 120%;
      padding-bottom: 10px; }

    @include screen-sm {
      font-size: 36px; }

    &--inner {
      display: block;
      padding-bottom: 40px;
      padding-right: 218px;
      opacity: 0;
      transform: translateY(20px);
      transition: opacity 300ms ease, transform 300ms ease;

      &[data-animated] {
        opacity: 1;
        transform: translateY(0); }

      @include screen-xxl {
        padding-bottom: 40px;
        padding-right: 160px; }

      @include screen-lg {
        padding-right: 0; }

      @include screen-md {
        padding-bottom: 24px; } } }

  &__title-inner {
    overflow: hidden;

    span {
      display: inline-block;
      transform: translateY(105%);
      transition: transform 600ms ease; }

    &[data-animated] {
      span {
        transform: translateY(0); } } }

  &__date {
    font-family: $bebas;
    font-size: 32px;
    line-height: 100%;
    color: #FFFFFF;
    margin-bottom: 80px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 300ms ease, transform 300ms ease;

    &[data-animated] {
      opacity: 1;
      transform: translateY(0); }

    @include screen-xxl {
      font-size: 28px;
      margin-bottom: 40px; }

    @include screen-md {
      font-size: 24px;
      margin-bottom: 32px; }

    span {
      color: #CFCFCF;
      opacity: 0.4; } }

  &__header-logo {
    width: 160px;
    height: 160px;
    flex-shrink: 0;
    position: absolute;
    top: 0;
    right: 0;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    opacity: 0;
    transition: opacity 300ms ease;

    &[data-animated] {
      opacity: 1; }

    @include screen-xxl {
      width: 120px;
      height: 120px; }

    @include screen-lg {
      width: 100%;
      height: 95px;
      position: relative;
      margin-bottom: 40px;
      padding: 30px; }

    @include screen-md {
      margin-bottom: 10px; }

    @include screen-sm {
      margin-left: -20px;
      margin-right: -20px;
      width: auto; }

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain; } }

  &__lead {
    position: relative;
    padding-bottom: 40px;

    @include screen-xxl {
      padding-bottom: 32px; }

    @include screen-lg {
      padding-bottom: 40px;
      padding-top: 42px; }

    &::before {
      content: '';
      position: absolute;
      top: 20px;
      left: -121px;
      width: 0;
      height: 3px;
      background: #fff;
      transition: width 600ms ease;

      @include screen-xxl {
        top: 16px; }

      @include screen-lg {
        left: 0;
        top: 0; } }

    &[data-animated] {

      &::before {
        width: 80px;

        @include screen-xxl {
          width: 60px; }

        @include screen-md {
          width: 40px; } }

      .swu__lead {

        &-title {
          opacity: 1;
          transform: translateY(0); }

        &-text {
          opacity: 1;
          transform: translateY(0); } } } }

  &__lead-title {
    font-weight: 400;
    font-size: 28px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #F7F7F7;
    position: relative;
    margin-bottom: 40px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 300ms ease, transform 300ms ease;

    @include screen-xxl {
      margin-bottom: 32px;
      font-size: 24px; }

    @include screen-md {
      font-size: 22px;
      margin-bottom: 16px; } }

  &__lead-text {
    font-weight: 300;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: #CFCFCF;

    opacity: 0;
    transform: translateY(20px);
    transition: opacity 300ms ease 150ms, transform 300ms ease 150ms;

    @include screen-xxl {
      font-size: 18px; }

    @include screen-md {
      font-size: 16px; }

    a {
      @extend .link; }

    p {
      padding-bottom: 26px;

      &:last-child {
        padding-bottom: 0; } }

    ul {
      padding-bottom: 20px;

      @include screen-xxl {
        padding-bottom: 20px; }

      li {
        font-size: 18px;
        line-height: 160%;
        font-weight: 300;
        letter-spacing: -0.02em;
        color: #cfcfcf;
        margin-bottom: 2px;
        padding-left: 24px;
        position: relative;

        @include screen-xxl {
          font-size: 16px;
          padding-left: 24px; }

        @include screen-md {
          padding-left: 17px;
          margin-bottom: 6px; }

        @include screen-sm {
          padding-left: 17px; }

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #CFCFCF;

          @include screen-xxl {
            left: 0; }

          @include screen-md {
            width: 5px;
            height: 5px;
            left: 0; } } } } }

  &__chart {
    position: absolute;
    top: -55px;
    left: 50%;
    transform: translateX(-50%);

    @include screen-xxl {
      top: -20px; }

    @include screen-lg {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      display: flex;
      justify-content: center;
      margin-bottom: 18px; } }

  &__btn2 {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 300ms ease, transform 300ms ease;

    &[data-animated] {
      opacity: 1;
      transform: translateY(0); }

    @include screen-md {
      width: 100%; } }

  &__btn {
    min-width: 348px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 300ms ease, transform 300ms ease;

    &[data-animated] {
      opacity: 1;
      transform: translateY(0); }

    @include screen-lg {
      min-width: auto; }

    @include screen-md {
      width: 100%; } }

  &__content {
    padding-bottom: 72px;

    @include screen-xxl {
      padding-bottom: 40px; }

    @include screen-md {
      padding-bottom: 44px; }

    @include screen-sm {
      padding-bottom: 34px; } }

  &__gallery {
    padding-bottom: 120px;

    @include screen-xxl {
      padding-bottom: 80px; }

    @include screen-md {
      padding-bottom: 70px; }

    &--inner {
      padding-bottom: 80px;

      @include screen-xxl {
        padding-bottom: 60px; }

      @include screen-md {
        padding-bottom: 40px; } } }

  &__help {
    padding-bottom: 119px;

    @include screen-xxl {
      padding-bottom: 80px; }

    @include screen-md {
      padding-bottom: 70px; }

    @include screen-sm {
      padding-bottom: 72px; } }

  &__help-title {
    font-weight: 500;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    margin-bottom: 70px;
    overflow: hidden;

    @include screen-xxl {
      font-size: 32px;
      margin-bottom: 40px; }

    @include screen-md {
      font-size: 28px; } }

  &__help-list {
    position: relative;
    padding-bottom: 60px;
    overflow: hidden;

    @include screen-xxl {
      padding-bottom: 40px; } }

  &__help-item {
    position: relative;

    &[data-animated] {

      &::before,
      &::after {
        transform: translateX(0) !important; } }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: rgba(#535353, .5);
      transform: translateX(-100%);
      transition: transform 600ms ease-in-out 600ms; }

    &:first-child {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: rgba(#535353, .5);
        transform: translateX(-100%);
        transition: transform 600ms ease-in-out 300ms; } } }

  &__help-footer {
    position: relative;
    padding: 0 160px;
    display: flex;
    justify-content: center;

    @include screen-md {
      flex-direction: column;
      align-items: center;
      padding: 0; } }

  &__help-counter {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;

    @include screen-md {
      position: relative;
      top: auto;
      transform: none;
      display: block;
      padding-bottom: 16px; } }

  &__help-counter-total {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #CFCFCF;

    @include screen-xxl {
      font-size: 14px; }

    @include screen-md {
      font-size: 16px; } }

  &__help-counter-current {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #F2F2F2;

    @include screen-xxl {
      font-size: 18px; }

    @include screen-md {
      font-size: 16px; } }

  &__help-btn {
    width: 100%;
    max-width: 466px;

    @include screen-md {
      max-width: 355px; } }

  &__tm-slider {
    padding-bottom: 100px;

    @include screen-xxl {
      padding-bottom: 60px; }

    @include screen-md {
      padding-bottom: 60px; }

    @include screen-sm {
      padding-bottom: 60px; } }

  &__thank-slider {
    padding-bottom: 100px;

    @include screen-xxl {
      padding-bottom: 60px; } }

  &__quote {
    padding-bottom: 120px;

    @include screen-xxl {
      padding-bottom: 80px; } }

  &__partners-list {}

  &__share {
    padding-bottom: 120px;

    @include screen-xxl {
      padding-bottom: 80px; }

    @include screen-md {
      padding-bottom: 80px; }

    @include screen-sm {
      padding-bottom: 60px; } } }
