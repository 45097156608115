.inner .header {
  background: rgba(0, 0, 0, .6);
  transition: background 300ms ease;

  .header__line {
    display: none; } }

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  opacity: 0;
  transition: opacity 300ms ease;

  &.open {

    .header {

      &__nav {
        display: block !important; } } }

  &:not(.collapsed) {

    .header {

      &__nav {
        @media only screen and (min-width: 1025px) {
          opacity: 1 !important;
          visibility: visible !important; } } } }

  &.disable-intro-delays {

    .header {

      &__logo {
        transition: transform 80ms ease;
        transform-origin: 0 50%;

        .header__letter {
          transition-name: transform;
          transition-duration: 80ms;
          transition-timing-function: ease;

          &--1 {
            transition-delay: 0ms; }
          &--2 {
            transition-delay: 40ms; }
          &--3 {
            transition-delay: 80ms; }
          &--4 {
            transition-delay: 120ms; }
          &--5 {
            transition-delay: 160ms; } } } } }

  &.collapsed {
    background: transparent;
    pointer-events: none;

    .header {

      &__line {
        opacity: 0; } }

    &.open, &.js-intro &.open {

      .header {

        &__logo {
          transition-delay: 0ms;
          transform: scale(1);

          .header__letter {
            transform: translate(0, 0) !important;

            &--1 {
              transition-delay: 200ms; }
            &--2 {
              transition-delay: 240ms; }
            &--3 {
              transition-delay: 280ms; }
            &--4 {
              transition-delay: 320ms; }
            &--5 {
              transition-delay: 360ms; } } } } }

    .header, &.js-intro .header {

      &__wrapper {}

      &__nav {
        pointer-events: all; }

      &__burger {
        pointer-events: all; }

      &__logo {
        transition: transform 300ms ease;
        transition-delay: 200ms;
        transform: scale(1);
        pointer-events: all;

        .header__letter {

          &--1 {
            transition-delay: 160ms; }
          &--2 {
            transition-delay: 120ms; }
          &--3 {
            transition-delay: 80ms; }
          &--4 {
            transition-delay: 40ms; }
          &--5 {
            transition-delay: 0ms; } }

        .header__letter:not(.header__letter--1) {
          transform: translate(0, 22px); } } }

    @media only screen and (min-width: 768px) {

      .header {

        &__logo {
          transition: transform 300ms ease;
          transition-delay: 200ms;
          transform: scale(1.2); } } }

    @media only screen and (min-width: 1025px) {

      .header {

        &__wrapper {
          min-height: 74px; }

        &__logo {
          transition: transform 300ms ease;
          transition-delay: 200ms;
          transform: scale(1.4); }

        &__overlay {
          display: block; }

        &__nav {
          display: none;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #15181b;
          z-index: 2;
          overflow-y: auto; }

        &__nav-wrapper {
          min-height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 90px 24px;
          position: relative; }

        &__list {
          flex-direction: column;
          width: 340px;
          padding: 20px 0; }

        &__item {
          width: 100%; }

        &__link {
          padding: 20px;
          width: 100%;
          border-bottom: 1px solid rgba(#535353, .5);
          text-align: center;
          font-size: 16px;
          line-height: 130%; }

        &__lang {
          position: absolute;
          bottom: 14px;
          left: 0;
          width: 100%;
          justify-content: center; } } }

    @media only screen and (min-width: 1280px) {

      .header {

        &__wrapper {
          min-height: 74px; }

        &__burger {
          position: relative;
          display: flex;
          top: 0;
          left: -12px; }

        &__lang {

          &--mobile {
            display: flex; }

          &--desktop {
            display: none; } } } }

    @media only screen and (min-width: 1440px) {

      .header {

        &__wrapper {
          min-height: 84px; } } }

    @media only screen and (min-width: 1491px) {

      .header {

        &__wrapper {
          min-height: 112px; }

        &__burger {
          position: relative;
          top: 0;
          left: -24px; }

        &__list {
          width: 450px;
          padding: 60px 0; }

        &__link {
          font-size: 30px;
          letter-spacing: 0;
          padding: 30px 24px; }

        &__lang {
          bottom: 43px; }

        &__lang-btn {
          font-size: 30px;
          padding: 20px 80px; } } } }

  &__overlay {
    display: none;
    @extend .preloader;

    @include screen-lg {
      display: block; } }

  &__container {
    padding: 0 70px;

    @include screen-xxl {
      padding: 0 52px; }

    @include screen-xl {
      padding: 0 46px; }

    @include screen-sm {
      padding: 0 20px; } }

  &__line {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    overflow: hidden;
    transition: opacity 300ms ease; }

  &__line-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(83, 83, 83, .5);
    transform: translateX(-100%);
    transition: transform 600ms ease-in-out; }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 0;
    position: relative;
    z-index: 3;

    @include screen-xxl {
      padding: 24px 0; }

    @include screen-xl {
      padding: 20px 0; }

    @include screen-lg {
      padding: 20px 0; }

    @include screen-sm {
      padding: 22px 0; } }

  &__logo {
    display: block;
    width: 106px;
    height: 20px;
    position: relative;
    z-index: 3;
    overflow: hidden;
    transition: transform 80ms ease;
    transform-origin: 0 50%;

    @include screen-xxl {
      width: 80px;
      height: 15px; }

    @include screen-xl {
      width: 71px;
      height: 14px; }

    @include screen-sm {
      width: 90px;
      height: 17px; }

    svg {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;

      .header__letter {
        transform: translate(0, 22px);
        transition-name: transform;
        transition-duration: 80ms;
        transition-timing-function: ease;

        &--1 {
          transition-delay: 0ms; }
        &--2 {
          transition-delay: 40ms; }
        &--3 {
          transition-delay: 80ms; }
        &--4 {
          transition-delay: 120ms; }
        &--5 {
          transition-delay: 160ms; } } } }

  &__nav {
    position: relative;

    &::-webkit-scrollbar {
      width: 7px;
      background-color: rgba(0, 0, 0, 0); }

    &::-webkit-scrollbar-track {
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0); }

    &::-webkit-scrollbar-thumb {
      background: rgba(207, 207, 207, .2);
      border-radius: 10px; }

    @include screen-lg {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #15181b;
      z-index: 2;
      overflow-y: auto; } }

  &__nav-wrapper {

    @include screen-lg {
      min-height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 90px 24px;
      position: relative; }

    @include screen-md {
      padding: 90px 24px 115px 24px; }

    @include screen-sm {
      padding: 71px 20px; } }

  &__list {
    display: flex;
    align-items: center;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 600ms ease, transform 600ms ease;

    @include screen-lg {
      flex-direction: column;
      width: 340px;
      padding: 20px 0; }

    @include screen-md {
      width: 450px;
      padding: 60px 0; }

    @include screen-sm {
      width: 310px; } }

  &__item {
    position: relative;

    @include screen-lg {
      width: 100%;

      &:last-child {

        .header__link {
          border-bottom: none; } } } }

  &__link {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #CFCFCF;
    display: block;
    padding: 10px 24px;
    transition: color 300ms ease;
    white-space: nowrap;

    body:not(.mobile) &:hover, &.active {
      color: #53F0CE;

      .ua {
        color: #53F0CE; } }

    @include screen-xxl {
      padding: 10px 22px;
      font-size: 12px;
      line-height: 16px; }

    @include screen-xl {
      font-size: 11px;
      line-height: 14px;
      padding: 10px 19px; }

    @include screen-lg {
      padding: 20px;
      width: 100%;
      border-bottom: 1px solid rgba(#535353, .5);
      text-align: center; }

    @include screen-md {
      font-size: 30px;
      letter-spacing: 0;
      padding: 30px 24px; }

    @include screen-sm {
      font-size: 22px;
      padding: 22px 20px; }

    .ua {
      color: #FFD600;
      transition: color 300ms ease;
      position: relative;

      &::before {
        content: '';
        width: 33px;
        height: 33px;
        position: absolute;
        top: -20px;
        right: -33px;
        background: url('../img/header/ua.svg') 50% 50% no-repeat;
        background-size: contain;

        @include screen-xxl {
          width: 26px;
          height: 26px;
          top: -12px;
          right: -28px; }

        @include screen-xl {
          width: 22px;
          height: 22px;
          top: -13px;
          right: -22px; }

        @include screen-md {
          top: -7px;
          right: -40px; }

        @include screen-sm {
          top: -12px;
          right: -36px; } } } }

  &__lang {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 600ms ease, transform 600ms ease;

    @include screen-lg {
      position: absolute;
      bottom: 14px;
      left: 0;
      width: 100%;
      justify-content: center; }

    @include screen-md {
      bottom: 43px; }

    @include screen-sm {
      bottom: 26px; }

    &--desktop {
      display: block;

      @include screen-lg {
        display: none; } }

    &--mobile {
      display: none;

      @include screen-lg {
        display: flex; } } }

  &__lang-btn {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #53F0CE;
    padding: 20px 40px;
    position: relative;

    @include screen-md {
      font-size: 30px;
      padding: 20px 80px; }

    @include screen-sm {
      font-size: 22px;
      padding: 12px 40px; }

    &::before {
      content: '';
      position: absolute;
      height: 18px;
      width: 1px;
      opacity: .5;
      background: #535353;
      top: 50%;
      right: 0;
      transform: translateY(-50%); }

    &:last-child {

      &::before {
        display: none; } }

    &.active {
      color: #CFCFCF;
      opacity: 0.4;
      pointer-events: none; } }

  &__burger {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    padding: 2px;
    z-index: 999999999;

    opacity: 0;
    transform: translateY(10px);
    transition: opacity 600ms ease, transform 600ms ease;

    @include screen-lg {
      display: flex; }

    @include screen-sm {
      width: 24px;
      height: 24px; }

    span {
      width: 100%;
      height: 2px;
      background: #53F0CE;
      margin-bottom: 8px;
      transition: all 300ms ease;

      @include screen-sm {
        margin-bottom: 6px; }

      &:last-child {
        margin-bottom: 0; } }

    &.active {

      span {

        &:first-child {
          transform: rotate(45deg) translate(3px, 4px);

          @include screen-sm {
            transform: rotate(45deg) translate(3px, 3px); } }

        &:last-child {
          transform: rotate(-45deg) translate(3px, -4px);

          @include screen-sm {
            transform: rotate(-45deg) translate(3px, -3px); } } } } }

  .btn {
    &--sm {
      height: 48px;
      padding: 0 30px;

      @include screen-xxl {
        height: 36px;
        padding: 0 24px; }

      @include screen-xl {
        height: 32px;
        padding: 0 20px; }

      @include screen-md {
        height: 48px;
        padding: 0 30px; }

      .btn {

        &__name {
          font-size: 16px;

          @include screen-xxl {
            font-size: 12px; }

          @include screen-xl {
            font-size: 11px; }

          @include screen-md {
            font-size: 14px; } } } } } }


.js-intro {

  .header {

    &__logo {

      .header__letter {
        &--1 {
          transition-delay: 1000ms; }
        &--2 {
          transition-delay: 1040ms; }
        &--3 {
          transition-delay: 1080ms; }
        &--4 {
          transition-delay: 1120ms; }
        &--5 {
          transition-delay: 1160ms; } } }

    &__list, &__lang, &__burger {
      transition-delay: 1000ms; } } }

[data-animated] {
  opacity: 1;

  .header {

    &__line-inner {
      transform: translateX(0); }

    &__logo {

      .header__letter {
        transform: translate(0, 0); } }

    &__list, &__lang, &__burger {
      opacity: 1;
      transform: translateY(0); } } }

