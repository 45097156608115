.mf-slider {
  position: relative;

  &__container {
    @include screen-sm {
      margin-left: -20px;
      margin-right: -20px;
      padding: 0 20px; }

    &[data-animated] {

      .mf-slider {

        &__author {
          opacity: 1;
          transform: translateY(0); }

        &__caption {
          opacity: 1;
          transform: translateY(0); } } } }

  &__item {
    height: auto;
    display: flex;
    flex-direction: column;

    @include screen-md {
      align-items: center; } }

  &__author {
    display: flex;
    align-items: center;
    padding-bottom: 40px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 600ms ease, transform 600ms ease;

    @include screen-xxl {
      padding-bottom: 30px; }

    @include screen-md {
      flex-direction: column;
      text-align: center;
      padding-bottom: 24px; } }

  &__icon {
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 32px;

    @include screen-xxl {
      width: 80px;
      height: 80px;
      margin-right: 24px; }

    @include screen-md {
      margin-right: 0;
      margin-bottom: 24px; }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__author-caption {
    position: relative; }

  &__name {
    font-weight: 500;
    font-size: 22px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    padding-bottom: 8px;

    @include screen-xxl {
      font-size: 20px; } }

  &__position {
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #CFCFCF;

    @include screen-xxl {
      font-size: 16px; } }

  &__caption {
    padding-left: 64px;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 600ms ease 300ms, transform 600ms ease 300ms;

    @include screen-xxl {
      padding-left: 48px; }

    @include screen-md {
      padding-left: 0;
      padding-top: 48px; }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
      background: url('../img/mf-slider/quote.svg') 50% 50% no-repeat;
      background-size: contain;

      @include screen-xxl {
        width: 24px;
        height: 24px; }

      @include screen-md {
        width: 32px;
        height: 32px;
        left: 50%;
        transform: translateX(-50%); } } }

  &__description {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #CFCFCF;
    padding-bottom: 32px;

    @include screen-xxl {
      font-size: 18px;
      padding-bottom: 24px; }

    @include screen-md {
      text-align: center;
      width: 100%;
      max-width: 510px;
      padding-bottom: 16px; }

    a {
      @extend .link; } }

  &__link {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #53F0CE;
    margin-top: auto;

    @include screen-xxl {
      font-size: 18px; }

    @include screen-md {
      text-align: center; }

    body:not(.mobile) &:hover {
      span {
        border-color: transparent; } }

    span {
      border-bottom: 1px solid #53F0CE;
      transition: border 300ms ease; } } }
