.badge {
  display: inline-flex;
  align-items: center;
  position: relative;

  @include screen-md {
    flex-direction: column-reverse; }

  &--yellow {

    .badge {

      &__content {
        background: #FF9F2F; }

      &__point {

        &::before {
          background: #FF9F2F; }

        &::after {
          border-color: #FF9F2F; } } } }

  &__wrapper {
    overflow: hidden; }

  &__content {
    display: flex;
    align-items: center;
    padding: 0 em(20);
    border-radius: em(2);
    background: #53F0CE;
    height: em(24);

    @include screen-md {
      padding: 0 20px;
      height: 22px; } }

  &__num {
    margin-right: em(10);

    @include screen-md {
      margin-right: 4px; } }

  &__num-value {
    font-weight: 600;
    font-size: em(14);
    line-height: 100%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #0C100F;

    @include screen-md {
      font-size: 12px; } }

  &__text {
    font-weight: 600;
    font-size: em(14);
    line-height: 100%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #0C100F;

    @include screen-md {
      font-size: 12px; } }

  &__point {
    width: em(112);
    height: 1px;
    position: relative;

    @include screen-md {
      width: 2px;
      height: 32px; }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: em(100);
      height: 1px;
      background: #53F0CE;
      opacity: 0.3;

      @include screen-md {
        width: 1px;
        height: 24px;
        top: auto;
        bottom: 0; } }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      width: em(8);
      height: em(8);
      border-radius: 50%;
      border: 2px solid #53F0CE;

      @include screen-md {
        width: 7px;
        height: 7px;
        border-width: 1px;
        top: 0;
        left: 50%;
        right: auto;
        transform: translate(-50%, 0); } } }

  &__list {
    position: relative; }

  &__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center; }

  &__item-wrapper {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center; } }


.js-animate, .js-st-animate {

  .badge {

    &__point {
      transform: translateX(50px);
      opacity: 0;
      transition: transform 300ms ease, opacity 300ms ease;

      @include screen-md {
        transform: translateY(20px); } }

    &__wrapper {
      transform: translateX(50px);
      transition: transform 300ms ease;

      @include screen-md {
        transform: translateY(20px); } }

    &__content {
      transform: translateX(100%);
      transition: transform 300ms ease;

      @include screen-md {
        transform: translateY(105%); } } }

  &[data-animated], &.active {

    .badge {

      &__point {
        transform: translateX(0);
        opacity: 1;

        @include screen-md {
          transform: translateY(0); } }

      &__wrapper {
        transform: translateX(0);

        @include screen-md {
          transform: translateY(0); } }

      &__content {
        transform: translateX(0);

        @include screen-md {
          transform: translateY(0); } } } } }
