.m-news {
  padding: em(60) 0 em(100) 0;
  display: flex;
  align-items: center;
  min-height: 100%;
  flex: 1;
  position: relative;
  z-index: 4;
  // margin-left: 10vw

  @include screen-md {
    margin: 0;
    padding: 60px 0;
    min-height: auto;
    display: block; }

  &__wrapper {
    position: relative; }

  &__navigation {
    align-items: center;

    @include screen-md {
      margin-top: 50px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 88px;
      padding-right: 88px;
      position: relative; }

    @include screen-sm {
      margin-top: 0;
      padding: 0; }

    &--desktop {
      display: flex;

      @include screen-md {
        display: none; } }

    &--mobile {
      display: none;

      @include screen-md {
        display: flex; }

      .m-news {

        &__more-btn {
          transition-delay: 300ms; } } }

    &[data-animated] {

      .m-news {

        &__more-btn {
          opacity: 1;
          transform: translateX(0); } } } }

  &__more-btn {
    margin-right: em(24);
    min-width: 220px;
    position: relative;
    z-index: 2;
    opacity: 0;
    transform: translateX(-20px);
    transition: transform 900ms ease 1200ms, opacity 900ms ease 1200ms;

    @include screen-md {
      margin: 0;
      width: 100%; } }

  &__content {
    display: flex;
    align-items: stretch;
    position: relative;
    margin-left: em(-45);
    margin-right: em(-45);

    @include screen-md {
      margin: 0;
      display: block; } }

  &__col {
    padding-right: 20px; } }
