.news-banner {
  position: relative;
  padding: 35px 0 35px 0;

  @include screen-xxl {
    padding: 24px 0 24px 0; }

  @include screen-md {
    padding: 25px 0; }

  @include screen-sm {
    padding: 20px 0; }

  &+p {
    padding-top: 35px;

    @include screen-xxl {
      padding-bottom: 25px; }

    @include screen-md {
      padding-top: 25px; }

    @include screen-sm {
      padding-top: 12px; } }

  &__wrapper {
    text-decoration: none !important;
    background: #0E1012;
    padding: 40px;
    display: flex;
    position: relative;

    @include screen-xxl {
      padding: 30px; }

    @include screen-md {
      padding: 24px;
      align-items: center; }

    @include screen-sm {
      flex-direction: column; } }

  &__image {
    flex-shrink: 0;
    width: 178px;
    position: relative;
    margin-right: 40px;

    @include screen-md {
      width: 100px;
      margin-right: 24px; }

    @include screen-sm {
      width: 100%;
      margin-right: 0;
      margin-bottom: 24px; }

    &::before {
      content: '';
      display: inline-block;
      padding-top: 56%; }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: 0 !important;
      padding: 0 !important; } }

  &__caption {
    display: flex;
    flex-direction: column; }

  &__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 110%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #FF9F2F;
    padding-bottom: 16px;

    @include screen-xxl {
      font-size: 16px; }

    @include screen-md {
      font-size: 14px; } }

  &__title {
    font-weight: 500;
    font-size: 30px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #53F0CE;

    @include screen-xxl {
      font-size: 24px; }

    @include screen-md {
      font-size: 20px;
      line-height: 130%; } } }
