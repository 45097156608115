.career {

  &__header {
    overflow: hidden;
    padding: 80px 0 42px 0;

    @include screen-xxl {
      padding: 60px 0 80px; }

    @include screen-xl {
      padding: 60px 0 60px; }

    @include screen-md {
      padding: 50px 0 80px; }

    @include screen-sm {
      padding: 32px 0 40px 0; } }

  &__header-wrapper {
    display: flex;
    position: relative;

    @include screen-sm {
      display: block; } }

  &__header-caption {
    width: 50%;

    @include screen-xl {
      width: 60%; }

    @include screen-md {
      width: 68%; }

    @include screen-sm {
      width: 100%;
      padding-bottom: 48px; } }

  &__header-title {
    font-weight: 400;
    font-size: 40px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    padding-bottom: 40px;
    transition-delay: 300ms !important;

    @include screen-xxl {
      font-size: 32px;
      padding-bottom: 32px; }

    @include screen-md {
      font-size: 26px;
      line-height: 130%; } }

  &__header-description {
    font-weight: 300;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: #CFCFCF;
    transition-delay: 450ms !important;

    @include screen-xxl {
      font-size: 18px; }

    @include screen-md {
      font-size: 16px;
      line-height: 160%;
      color: #CFCFCF; }

    a {
      @extend .link; } }

  &__header-image {
    width: 50%;
    position: relative;
    min-height: 656px;
    transition-delay: 600ms !important;

    @include screen-xxl {
      min-height: 459px; }

    @include screen-xl {
      width: 40%; }

    @include screen-md {
      width: 32%; }

    @include screen-sm {
      width: 100%;
      min-height: 380px;
      transition-delay: 300ms !important; }

    img {
      position: absolute;
      display: block;
      top: -250px;
      left: 55%;
      transform: translate(-50%, 0);
      width: 991px;
      height: 1087px;
      object-fit: contain;

      @include screen-xxl {
        width: 693px;
        height: 760px;
        top: -110px; }

      @include screen-xl {
        left: 70%; }

      @include screen-md {
        left: 95%;
        width: 623px;
        height: 684px; }

      @include screen-sm {
        width: 535px;
        height: 588px;
        left: 48%;
        top: -105px; } } }

  &__content {}

  &__with-us {
    padding-bottom: 90px;

    @include screen-xxl {
      padding-bottom: 60px; }

    @include screen-sm {
      padding-bottom: 20px; } }

  &__vacancies {
    padding-bottom: 110px;

    @include screen-xxl {
      padding-bottom: 90px; }

    @include screen-md {
      padding-bottom: 80px; }

    @include screen-sm {
      padding-bottom: 60px; } }

  &__banner {
    padding-bottom: 120px;

    @include screen-xxl {
      padding-bottom: 100px; }

    @include screen-md {
      padding-bottom: 80px; }

    @include screen-sm {
      padding-bottom: 60px; } }

  &__benefits {
    padding-bottom: 120px;

    @include screen-xxl {
      padding-bottom: 100px; }

    @include screen-md {
      padding-bottom: 80px; }

    @include screen-sm {
      padding-bottom: 60px; } }

  &__hire {
    padding-bottom: 120px;

    @include screen-xxl {
      padding-bottom: 90px; }

    @include screen-md {
      padding-bottom: 80px; }

    @include screen-sm {
      padding-bottom: 60px; }

    h3 {
      width: 100%;
      max-width: 460px;
      padding-bottom: 40px; } }

  &__hire-article {
    padding-bottom: 90px;

    @include screen-xxl {
      padding-bottom: 60px; }

    @include screen-md {
      padding-bottom: 60px; }

    @include screen-sm {
      padding-bottom: 40px; } }

  &__hire-list {
    position: relative; }

  &__feedback {
    padding-bottom: 80px;

    @include screen-xxl {
      padding-bottom: 60px; }

    @include screen-md {
      padding-bottom: 40px;
      text-align: center; }

    @include screen-md {
      padding-bottom: 20px; } }

  &__banner-dark {
    padding-bottom: 60px; } }

.career-inner {

  &__about {
    padding-bottom: 28px;

    @include screen-xxl {
      padding-bottom: 12px; }

    @include screen-md {
      padding-bottom: 14px; } }

  &__share {
    padding-bottom: 80px;

    @include screen-xxl {
      padding-bottom: 60px; }

    @include screen-md;

    @include screen-sm {
      padding-bottom: 48px; } }

  &__share-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include screen-lg {
      flex-direction: column;
      align-items: flex-start; }

    @include screen-sm {
      align-items: center; } }

  &__share-btn {

    @include screen-lg {
      width: 100%;
      padding-bottom: 24px; } }

  &__share-socials {
    position: relative; }

  &__article {
    padding: 32px 0 62px 0;

    @include screen-xxl {
      padding: 36px 0 42px 0; }

    @include screen-md {
      padding: 24px 0 30px 0; } }

  &__article-2 {
    padding: 80px 0;

    @include screen-xxl {
      padding: 60px 0; }

    @include screen-md {
      padding: 48px 0; } }

  &__about-company {
    padding: 120px 0 0;

    @include screen-xxl {
      padding: 90px 0 0; }

    @include screen-md {
      padding: 80px 0 0; } }

  &__about-company-article {
    padding-bottom: 60px;

    @include screen-xxl {
      padding-bottom: 40px; }

    @include screen-md {
      padding-bottom: 36px; }

    @include screen-sm {
      padding-bottom: 16px; } }

  &__benefits-2 {
    padding-bottom: 120px;

    @include screen-xxl {
      padding-bottom: 90px; }

    @include screen-md {
      padding-bottom: 60px; }

    @include screen-sm {
      padding-bottom: 40px; } }

  &__plates {
    padding-bottom: 70px;

    @include screen-xxl {
      padding-bottom: 50px; }

    @include screen-md {
      padding-bottom: 56px; }

    @include screen-sm {
      padding-bottom: 36px; } }

  &__banner-light {
    padding-bottom: 120px;

    @include screen-xxl {
      padding-bottom: 90px; }

    @include screen-md {
      padding-bottom: 80px; }

    @include screen-sm {
      padding-bottom: 60px; } } }

.vacancy-header {
  position: relative;
  z-index: 1;
  padding-bottom: 52px;

  @include screen-xxl {
    padding-bottom: 32px; }

  @include screen-md {
    padding-bottom: 24px; }

  &__wrapper {
    position: relative; }

  &__backlink {
    position: absolute;
    top: 0;
    left: -122px;

    @include screen-xxl {
      left: -100px; }

    @include screen-md {
      left: -70px; }

    @include screen-sm {
      left: auto;
      position: relative;
      margin-bottom: 24px; } }

  &__title {
    font-weight: 500;
    font-size: 80px;
    line-height: 110%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 40px;
    overflow: hidden;

    @include screen-xxl {
      font-size: 64px;
      margin-bottom: 28px; }

    @include screen-md {
      font-size: 36px;
      margin-bottom: 20px;
      margin-top: 10px; }

    @include screen-sm {
      margin-top: 0; }

    span {
      display: inline-block;
      transform: translateY(105%);
      transition: transform 600ms ease; }

    &[data-animated] {

      span {
        transform: translateY(0); } } }

  &__list {
    display: flex;
    padding-bottom: 60px;

    @include screen-xxl {
      padding-bottom: 40px; }

    @include screen-md {
      padding-bottom: 32px; }

    @include screen-sm {
      flex-wrap: wrap; } }

  &__list-item {
    font-family: $bebas;
    font-size: 32px;
    line-height: 100%;
    color: #FFFFFF;
    text-transform: uppercase;
    padding: 0 16px;
    position: relative;

    @include screen-xxl {
      font-size: 24px;
      padding: 0 12px; }

    &::before {
      content: '';
      position: absolute;
      top: 1px;
      right: -1px;
      height: 26px;
      width: 2px;
      background: rgba(#D9D9D9, .4);

      @include screen-xxl {
        height: 20px; } }

    &:last-child {

      &::before {
        display: none; } }

    &--location {
      padding-left: 40px;

      @include screen-xxl {
        padding-left: 34px;

        @include screen-sm {
          padding-left: 0;
          width: 100%;
          padding-bottom: 8px;

          &::before {
            display: none; }

          &+.vacancy-header__list-item {
            padding-left: 0; } } }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        background: url('../img/career/location.svg') 50% 50% no-repeat;
        background-size: contain;

        @include screen-xxl {
          width: 22px;
          height: 22px; }

        @include screen-sm {
          display: none; } } }

    &--orange {
      color: #FF9F2F; } }

  &__tags {
    display: flex;
    flex-wrap: wrap; }

  &__tag {
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    padding: 7px 26px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 30px;
    margin-right: 12px;
    margin-bottom: 12px;
    flex-shrink: 0;

    @include screen-xxl {
      font-size: 15px;
      margin-right: 10px;
      margin-bottom: 12px; }

    @include screen-md {
      margin-right: 8px;
      margin-bottom: 12px;
      padding: 7px 20px; } } }

