.m-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #15181b;
  z-index: 1;

  @include screen-sm {
    display: none; }

  &.hide-logo {

    .m-bg {

      &__logo {
        display: none; } } }

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-10%, -50%);
    width: em(2851);
    height: em(702);
    background: url('../img/m-bg/logo.svg') 50% 50% no-repeat;
    background-size: contain;
    z-index: 10;
    mix-blend-mode: difference;

    @include screen-md {
      width: 1726px;
      height: 424px;
      transform: translate(-21%, -15%); } }

  &__gradient {
    position: absolute;
    z-index: 2;

    &::before {
      content: '';
      display: block;
      padding-top: 100%; }

    &--green {
      width: 48%;
      top: -12vw;
      left: -12vw;
      background: radial-gradient(52% 52%,#132A26 0%, #151A1C 73.96%, #15181B 100%);
      animation: fly 10s linear infinite; }

    &--black {
      width: 38%;
      bottom: -12vw;
      right: -12vw;
      background: radial-gradient(52% 52%, #0e1013 0%, #14171A 79.69%, #15181B 100%);
      animation: fly 12s 1s linear infinite; } } }


