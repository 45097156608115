.contacts {

  .get-in-touch {
    display: none !important; }

  &__content {
    padding: 80px 0 0 0;
    position: relative;
    z-index: 2;

    @include screen-md {
      padding: 50px 0 0 0; } }

  &__header {
    padding-bottom: 60px;

    @include screen-md {
      padding-bottom: 48px; }

    @include screen-sm {
      padding-bottom: 40px; } }

  &__caption {
    position: relative;
    text-align: center;

    @include screen-sm {
      padding: 0;
      width: 100%; } }

  &__title {
    font-weight: 500;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    padding-bottom: 8px;

    @include screen-md {
      font-size: 28px; } }

  &__link {
    display: inline-block;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #53F0CE;
    margin-bottom: 40px;

    @include screen-md {
      font-size: 20px;
      margin-bottom: 24px; } }

  &__description {
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #F2F2F2;

    @include screen-md {
      font-size: 20px; } } }
