.slider-pagination {
  padding: 100px 0 40px 0;
  position: relative;
  display: flex;
  justify-content: center;

  @include screen-xxl {
    padding: 60px 0 40px 0; }

  @include screen-md {
    padding: 80px 0 40px 0; }

  @include screen-sm {
    padding: 60px 20px 40px 20px;
    margin-left: -20px;
    margin-right: -20px; }

  &--sm {
    padding: 20px 0 !important;

    &::before {
      top: 24px !important; } }

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 104px;
    width: 90%;
    transform: translateX(-50%);
    height: 1px;
    background: #535353;
    opacity: .5;

    @include screen-xxl {
      top: 64px; }

    @include screen-md {
      top: 84px;
      width: 100%; }

    @include screen-sm {
      top: 64px; } }

  &__container {
    padding: 0 40px;
    box-sizing: content-box;
    position: relative;
    background-color: #15181B;

    @include screen-sm {
      padding: 0 17px; } }

  .swiper-pagination {
    position: relative;
    bottom: 0;
    white-space: nowrap; }

  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    transform: scale(1); }

  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(1); }

  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transform: scale(1);
    width: 6px;
    height: 6px;
    margin: 2px 7px;
    background: rgba(217, 217, 217, 0.2) !important;
    opacity: 1 !important;
    transition: .2s transform, .2s left;
    flex-shrink: 0;

    &::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background: #53F0CE;
      top: -2px;
      left: -2px;
      border-radius: 50%;
      z-index: -1;
      opacity: 0;
      transition: opacity .2s; }

    &::after {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #15181B;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity .2s; }

    &-active {
      position: relative;
      transform: scale(1);
      background: #15181B !important;

      &::before,
      &::after {
        opacity: 1; } } } }
