.chart {

  &[data-animated] {

    .chart {

      &__circle svg {
        stroke-dashoffset: 0; }

      &__circle-2 {
        transform: rotate(0deg);
        opacity: 1; }

      &__caption {
        transform: scale(1); }

      &__icon {
        transform: scale(1); }

      &__text {
        opacity: 0.5; }

      &__value {

        span {
          transform: translateY(0); } } } }

  &__wrapper {
    position: relative;
    width: 426px;
    height: 426px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include screen-xxl {
      width: 380px;
      height: 380px; }

    @include screen-md {
      width: 350px;
      height: 350px; } }

  &__circle {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 100%;
    height: 100%;

    svg {
      display: block;
      width: 100%;
      height: 100%;
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      transition: all 900ms ease; } }

  &__circle-2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(314deg);
    opacity: 0;
    transition: transform 900ms ease, opacity 300ms ease 300ms; }

  &__circle-2-shape {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80.5%;
    height: 80.5%;

    svg {
      position: absolute;
      right: 2.1%;
      bottom: 1.1%;
      display: block;
      width: 30.62%;
      height: 28.58%; } }

  &__caption {
    position: relative;
    width: 265px;
    height: 265px;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    transition: transform 600ms ease;

    @include screen-xxl {
      width: 240px;
      height: 240px; }

    @include screen-md {
      width: 220px;
      height: 220px; } }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    text-align: center;
    padding-bottom: 20px;

    @include screen-md {
      padding-bottom: 10px; }

    &--pb-0 {
      padding-bottom: 0 !important; } }

  &__circle-3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #000000; }

  &__top {
    padding-bottom: 20px;

    @include screen-md {
      padding-bottom: 8px; } }

  &__bottom {
    position: relative; }

  &__icon {
    width: 32px;
    height: 32px;
    margin-bottom: 16px;
    transform: scale(0);
    transition: transform 600ms ease 600ms;

    @include screen-md {
      width: 28px;
      height: 28px;
      margin-bottom: 13px; }

    img {
      display: block;
      width: 100%;
      height: 100%; } }

  &__value {
    font-weight: 500;
    font-size: 40px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    margin-bottom: 4px;
    overflow: hidden;

    @include screen-xxl {
      font-size: 32px; }

    @include screen-md {
      font-size: 36px; }

    &--md {
      font-size: 28px;
      line-height: 110%;
      color: #FF9F2F;
      font-weight: 400;
      margin: 4px 0 12px 0;

      @include screen-xxl {
        font-size: 24px; }

      @include screen-md {
        margin: 0 0 12px 0;
        font-size: 24px; } }

    &--sm {
      font-size: 20px;
      letter-spacing: -0.02em;
      color: #FF9F2F;

      @include screen-xxl {
        font-size: 18px; }

      @include screen-md {
        font-size: 22px; } }

    span {
      display: inline-block;
      transform: translateY(105%);
      transition: transform 600ms ease 600ms; } }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    opacity: 0;
    transition: opacity 600ms ease 600ms;

    @include screen-xxl {
      font-size: 14px; }

    @include screen-md {
      font-size: 16px; } } }

@keyframes c-rotate {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(360deg); } }
