.blockquote-2 {
  font-weight: 500;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #F2F2F2;
  padding-left: 64px;
  position: relative;
  overflow: hidden;

  &[data-animated] {

    &::before {
      transform: translateY(0); }

    span {
      opacity: 1;
      transform: translateX(0); } }

  @include screen-xxl {
    font-size: 32px;
    padding-left: 48px; }

  @include screen-md {
    padding-left: 32px;
    font-size: 26px; }

  @include screen-sm {
    margin: 0 10px; }

  a {
    @extend .link; }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background: #53F0CE;
    transform: translateY(-100%);
    transition: transform 600ms ease;

    @include screen-xxl {
      width: 4px; } }

  span {
    display: inline-block;
    opacity: 0;
    transform: translate(-20px);
    transition: opacity 600ms ease, transform 600ms ease; } }

