.yt-video {
  width: 100%;
  position: relative;

  &__container {
    width: 100%;
    position: relative;

    &::before {
      content: '';
      display: inline-block;
      padding-top: 56%; } }

  &__player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

  &__preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 300ms ease-out;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 2;
      transition: opacity 300ms ease; } }

  &__poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; }

  &__play-button {
    width: 120px;
    height: 120px;
    transition: $transition;
    position: relative;
    z-index: 3;
    transition: opacity 600ms ease;

    @include screen-xxl {
      width: 90px;
      height: 90px; }

    @include screen-md {
      width: 56px;
      height: 56px; }

    svg {
      display: block;
      width: 100%;
      height: 100%;

      path {
        fill: #53F0CE;
        transition: fill 300ms ease; } } }

  body:not(.mobile) &:hover {

    .yt-video {

      &__preview {

        &::before {
          opacity: 0; } }

      &__play-button {

        svg path {
          fill: #fff; } } } }

  &.is-active {

    body:not(.mobile) &:hover {

      .yt-video {

        &__play-button {
          opacity: 0;

          @include screen-md {
            opacity: 1; } } } } }

  &.active {

    .yt-video {

      &__preview {
        opacity: 0;
        visibility: hidden; } } } }
