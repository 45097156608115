.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 10;
  width: 100%;
  overflow-y: overlay;
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms ease, visibility 300ms ease;
  min-width: 320px;
  backdrop-filter: blur(8px);
  background: rgba(15, 15, 15, 0.8);

  &::-webkit-scrollbar {
    width: 7px;
    background-color: rgba(0, 0, 0, 0); }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0); }

  &::-webkit-scrollbar-thumb {
    background: rgba(207, 207, 207, .2);
    border-radius: 10px; }

  &.active {
    opacity: 1;
    visibility: visible;

    .modal__container {
      transform: translateY(0); } }

  &.modal-succes {

    .modal {

      &__close {
        display: none; } } }

  &__wrapper {
    position: relative;
    display: flex;
    min-height: 100%;
    width: 100%;
    padding: 80px 40px;
    z-index: 2;

    @include screen-xl {
      padding: 40px; }

    @include screen-sm {
      padding: 24px 16px; } }

  &__overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1; }

  &__container {
    position: relative;
    width: 100%;
    max-width: 684px;
    z-index: 2;
    background: #0A0B0C;
    border-radius: 40px;
    pointer-events: all;
    margin: auto;
    overflow: unset;
    transform: translateY(50px);
    transition: transform 600ms ease-out;

    @include screen-xl {
      max-width: 604px;
      border-radius: 30px; }

    @include screen-md {
      max-width: 500px;
      border-radius: 20px; }

    @include screen-sm {
      max-width: 480px; } }

  &__close {
    width: 40px;
    height: 40px;
    padding: 40px;
    box-sizing: content-box;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    opacity: .5;

    @include screen-xl {
      width: 32px;
      height: 32px;
      padding: 24px; }

    @include screen-md {
      width: 32px;
      height: 32px;
      padding: 16px; }

    svg {
      display: block;
      width: 100%;
      height: 100%;
      stroke: #25282A; } }

  &__content {
    padding: 48px;
    position: relative;
    z-index: 2;

    @include screen-xxl {
      padding: 40px; }

    @include screen-md {
      padding: 24px; }

    @include screen-sm {
      padding: 16px; }

    &--success {
      padding: 90px 48px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include screen-xxl {
        padding: 60px 40px; }

      @include screen-md {
        padding: 40px 80px; }

      @include screen-sm {
        padding: 40px 32px; } } }

  &__header {
    padding-bottom: 32px;

    @include screen-md {
      padding-bottom: 18px; } }

  &__title {
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    padding-bottom: 8px;

    @include screen-md {
      font-size: 24px; } }

  &__subtitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #53F0CE;

    @include screen-md {
      font-size: 16px; } } }

.modal-succes {

  &__icon {
    width: 84px;
    height: 84px;
    margin-bottom: 32px;

    @include screen-xxl {
      width: 64px;
      height: 64px; }

    @include screen-md {
      margin-bottom: 16px; }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain; } }

  &__title {
    font-weight: 500;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    padding-bottom: 8px;

    @include screen-xxl {
      font-size: 36px; }

    @include screen-md {
      font-size: 28px;
      padding-bottom: 12px; } }

  &__text {
    font-weight: 400;
    font-size: 22px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #CFCFCF;
    padding-bottom: 50px;

    @include screen-xxl {
      font-size: 18px;
      padding-bottom: 32px; }

    @include screen-md {
      font-size: 16px;
      padding-bottom: 18px;
      width: 100%;
      max-width: 290px; } }

  &__button {
    width: 342px;

    @include screen-xxl {
      width: 300px; }

    @include screen-md {
      width: 100%; } } }

