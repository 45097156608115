.news {
  position: relative;

  &__content {
    padding-top: 80px;

    @include screen-xxl {
      padding-top: 48px; }

    @include screen-xl {
      padding-top: 40px; }

    @include screen-md {
      padding-top: 50px; }

    @include screen-sm {
      padding-top: 40px; } }

  &__list {}

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -45px;
    margin-right: -45px;

    @include screen-xxl {
      margin-left: -30px;
      margin-right: -30px; }

    @include screen-xl {
      margin-left: -20px;
      margin-right: -20px; }

    @include screen-sm {
      margin: 0; } }

  &__col {
    width: calc(100% / 3);
    padding: 0 45px 90px 45px;

    @include screen-xxl {
      padding: 0 30px 60px 30px; }

    @include screen-xl {
      padding: 0 20px 50px 20px; }

    @include screen-md {
      width: calc(100% / 2); }

    @include screen-sm {
      width: 100%;
      padding: 0 0 40px 0; } }

  &__pagination {
    display: flex;
    flex-direction: column;
    align-items: center; }

  &__pagination-container {
    padding-bottom: 60px;

    @include screen-md {
      padding-bottom: 50px; }

    @include screen-sm {
      padding-bottom: 40px; } }

  &__pagination-btn {
    width: 100%;
    max-width: 466px;
    display: flex;
    justify-content: center;

    @include screen-md {
      max-width: 335px; } } }
