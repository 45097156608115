.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 999999;
  transition: all 600ms ease;
  background: #15181B;

  &.is-loaded {
    opacity: 0;
    visibility: hidden; }

  &__box {
    width: 120px;
    height: 120px;
    position: relative;
    transform: scale(0.8);
    opacity: 0;
    overflow: hidden; }

  &__box-2 {
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #15181B;
    z-index: 10; }

  &__box-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #53F0CE;
    transform: translateY(100%); }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 48px;
    height: 48px;
    transform: translate(-50%, -50%); } }
