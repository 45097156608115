.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  padding: 0 em(50);
  background: #53F0CE;
  height: em(64);
  border-radius: em(70);
  cursor: pointer;
  box-shadow: none;
  transition: background 300ms ease, width 300ms ease, border 50ms ease 250ms;
  position: relative;
  overflow: hidden;
  border: none;
  isolation: isolate;

  @include screen-xxl {
    height: em(56);
    border-radius: em(60); }

  @include screen-md {
    height: 48px;
    padding: 0 24px; }

  &.is-loading {
    pointer-events: none;
    cursor: auto;
    padding: 0 !important;
    width: em(64);
    background: #53F0CE;

    @include screen-xxl {
      width: em(56); }

    @include screen-md {
      width: 48px; }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: em(40);
      height: em(40);
      background: url('../img/btn/spinner.svg') 50% 50% no-repeat;
      background-size: contain;
      z-index: 3;

      @include screen-xxl {
        width: em(36);
        height: em(36); }

      @include screen-md {
        width: 30px;
        height: 30px; } }

    .btn {

      &__bg {
        opacity: 0 !important; }

      &__name {
        display: none !important; }

      &__arrow {
        display: none !important; } } }

  &__bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    background: #fff;
    width: 110%;
    transition: transform 600ms ease, opacity 300ms ease;
    border-radius: 50%;
    opacity: 0;
    z-index: 1;

    &::before {
      content: '';
      display: inline-block;
      padding-top: 100%; } }

  &__name {
    font-weight: 600;
    font-size: em(18);
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #0C100F;
    transition: color 300ms ease;
    position: relative;
    z-index: 2;
    white-space: nowrap;

    @include screen-xxl {
      font-size: em(16); }

    @include screen-md {
      font-size: 16px; } }

  &__arrow {
    width: em(24);
    height: em(24);
    flex-shrink: 0;
    position: relative;
    z-index: 2;

    svg {
      display: block;
      width: 100%;
      height: 100%;
      stroke: #0C100F;
      transition: stroke 300ms ease; } }

  body:not(.mobile) &:hover {

    .btn {

      &__bg {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1.6); } } }

  &:active {

    .btn {

      &__bg {
        background: #D8FFF7; } } }

  &:disabled, &.disabled {
    background: #399682;
    pointer-events: none;

    .btn {

      &__name {
        color: #21322E; }

      &__bg {
        display: none; } } }

  &--orange {
    background: #FF9F2F; }

  &--white {
    background: #ffffff;

    .btn {

      &__bg {
        background: #53F0CE; } } }

  &--bordered {
    background: none;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      border: 2px solid #53F0CE;
      border-radius: inherit; }

    .btn {

      &__name {
        font-weight: 500;
        color: #53F0CE; }

      &__bg {
        background: #53F0CE; }

      &__arrow {

        svg {
          stroke: #53F0CE; } } }

    body:not(.mobile) &:hover {

      .btn {

        &__name {
          color: #0C100F; }

        &__arrow {

          svg {
            stroke: #0C100F; } } } }

    &:active {

      .btn {

        &__bg {
          background: #53F0CE; } } }

    &.is-loading {
      background: #53F0CE !important; } }

  &--mfw {

    @include screen-sm {
      width: 100%; } }

  &--fw {
    width: 100%; }

  &--xl {
    height: 70px;
    line-height: 70px;

    @include screen-xxl {
      height: 60px;
      line-height: 60px; }

    @include screen-md {
      height: 56px;
      line-height: 56px; }

    &.is-loading {
      width: 70px;

      @include screen-xxl {
        width: 60px; }

      @include screen-md {
        width: 56px; }

      &::before {
        width: 44px;
        height: 44px;

        @include screen-xxl {
          width: 40px;
          height: 40px; }

        @include screen-md {
          width: 36px;
          height: 36px; } } }

    .btn {

      &__name {
        font-size: 20px;

        @include screen-md {
          font-size: 18px; } } } }

  &--md {
    height: 56px;

    &.is-loading {
      width: 56px; } }

  &--sm {
    height: em(48);
    padding: 0 em(30);

    @include screen-md {
      height: 48px; }

    .btn {

      &__name {
        font-size: em(16);

        @include screen-md {
          font-size: 14px; } } } }

  &--xs {
    height: em(40);
    padding: 0 em(21);

    @include screen-md {
      padding: 0 20px;
      height: 40px; }

    .btn {

      &__name {
        font-size: em(14);
        font-weight: 500;

        @include screen-md {
          font-size: 14px; } } } }

  &--xxs {
    height: 34px;
    padding: 0 35px;

    @include screen-md {
      padding: 0 35px;
      height: 34px; }

    .btn {

      &__name {
        font-size: 14px;
        font-weight: 500;

        @include screen-md {
          font-size: 14px; } } } }

  &--more {

    @include screen-xxl {
      padding: 0 em(30); }

    .btn {

      &__name-wrapper {
        display: flex;
        justify-content: flex-end;
        max-width: 0;
        overflow: hidden; }

      &__name {
        padding-left: em(3);
        padding-right: em(10);
        white-space: nowrap; } }

    body:not(.mobile) &:hover {

      .btn {

        &__name-wrapper {
          display: block;
          max-width: 100%; } } } }

  &--toggle {

    .btn {

      &__name-wrapper {
        display: flex;
        justify-content: flex-end;
        max-width: 0;
        overflow: hidden; }

      &__name {
        white-space: nowrap; } }

    body:not(.mobile) &:hover {

      .btn {

        &__name-wrapper {
          display: block;
          max-width: 100%; }

        &__arrow {
          display: none; } } } } }

.btn2 {
  position: relative;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  height: em(70);
  padding: 0 em(77) 0 em(105);
  border-radius: em(70);
  background: #53F0CE;
  z-index: 3;
  overflow: hidden;

  @include screen-md {
    height: 56px; }


  body:not(.mobile) &:hover {

    span {

      &::before {
        transform: translateY(-50%) scale(30);

        @include screen-md {
          transform: translateY(-50%); } } } }

  span {
    display: inline-block;
    font-weight: 600;
    font-size: em(20);
    line-height: 100%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #0C100F;
    position: relative;
    z-index: 2;

    @include screen-md {
      font-size: 18px; }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: em(-40);
      transform: translateY(-50%);
      width: em(20);
      height: em(20);
      border-radius: 50%;
      background: #fff;
      transition: transform 300ms ease;
      z-index: -1; }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: em(-36);
      transform: translateY(-50%);
      width: em(12);
      height: em(12);
      border-radius: 50%;
      background: #53F0CE;
      z-index: -1; } }

  &--yellow {
    border: 3px solid #FFCB14;
    background: #FFCB14;

    @include screen-xxl {
      height: 64px;
      border-width: 2px; }

    @include screen-md {
      height: 56px; }

    span {

      &::before {
        left: -44px;
        width: 26px;
        height: 26px; }

      &::after {
        left: -44px;
        width: 26px;
        height: 26px;
        background: url('../img/btn/ua.svg') 50% 50% no-repeat;
        background-size: cover; } } } }
