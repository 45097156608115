.news-slider {
  padding-top: em(60);

  @include screen-md {
    padding-top: 50px;
    width: 100%;
    overflow: visible; }

  @include screen-sm {
    padding-top: 40px;
    overflow: hidden; }

  .swiper-wrapper {

    @include screen-sm {
      display: block !important; } }

  &__item {
    width: 33.333333%;
    padding: 0 em(45);
    height: auto;

    @include screen-md {
      width: 335px;
      padding: 0; }

    @include screen-sm {
      width: 100% !important;
      display: none;
      padding-bottom: 40px; }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      display: block; } } }

