.cf-plate {
  position: sticky;
  bottom: 20px;
  z-index: 99;
  padding: 0 40px 0 40px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms ease, visibility 300ms ease;

  @include screen-xxl {
    padding: 0 32px 0 32px;
    bottom: 16px; }

  @include screen-md {
    padding: 0 24px 0 24px;
    bottom: 12px; }

  @include screen-sm {
    padding: 0 0 0 0;
    bottom: 0; }

  &.active {
    opacity: 1;
    visibility: visible; }

  &__container {
    border-radius: 16px;
    background: linear-gradient(180deg, rgba(37, 39, 41, 0.60) 0%, rgba(49, 52, 55, 0.60) 100%);
    backdrop-filter: blur(12px);

    @include screen-md {
      border-radius: 0; } }

  &__wrapper {
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;

    @include screen-xxl {
      padding: 20px 0;
      gap: 32px; }

    @include screen-md {
      gap: 24px;
      padding: 16px 0; }

    @include screen-sm {
      text-align: center; } }

  &__text {
    color: #F2F2F2;
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.48px;

    @include screen-xxl {
      font-size: 20px; }

    @include screen-md {
      font-size: 18px; }

    @include screen-sm {
     display: none; } }

  &__button {

    @include screen-sm {
      width: 100%; } } }
