.company {

  &__header {
    overflow: hidden;
    padding-bottom: 120px;

    @include screen-xxl {
      padding-bottom: 90px; }

    @include screen-md {
      padding-bottom: 56px; } }

  &__header-caption {
    padding: 80px 0 0 0;

    @include screen-xxl {
      padding: 60px 0 0 0; }

    @include screen-md {
      padding: 50px 0 0 0; }

    @include screen-sm {
      width: 100%;
      padding: 32px 0 0 0; } }

  &__header-title {
    font-weight: 400;
    font-size: 32px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    padding-bottom: 40px;

    @include screen-xxl {
      font-size: 26px;
      padding-bottom: 32px;
      line-height: 130%; } }


  &__header-description {
    font-weight: 300;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: #CFCFCF;

    @include screen-xxl {
      font-size: 18px;
      line-height: 26px; }

    @include screen-md {
      font-size: 16px;
      line-height: 26px; }

    a {
      @extend .link; }

    p {
      padding-bottom: 32px;

      @include screen-xxl {
        padding-bottom: 28px; }

      @include screen-md {
        padding-bottom: 26px; } } }

  &__header-image {
    display: flex;

    img {
      display: block;
      width: 100%;
      height: auto;

      @include screen-md {
        width: 410px;
        position: relative;
        top: 44px;
        left: -71px; }

      @include screen-sm {
        width: 100%;
        position: relative;
        top: 0;
        left: 0; } } }

  &__gallery {
    overflow: hidden;
    padding-bottom: 140px;

    @include screen-xxl {
      padding-bottom: 90px; }

    @include screen-md {
      padding-bottom: 80px; }

    @include screen-sm {
      padding-bottom: 60px; } }

  &__techs {
    padding-bottom: 60px;

    @include screen-xxl {
      padding-bottom: 50px; }

    @include screen-md {
      padding-bottom: 40px; }

    @include screen-sm {
      padding-bottom: 20px; } }

  &__facts {
    overflow: hidden;
    padding-bottom: 110px;

    @include screen-xxl {
      padding-bottom: 70px; }

    @include screen-md {
      padding-bottom: 80px; }

    @include screen-sm {
      padding-bottom: 60px; } }

  &__values {
    padding-bottom: 40px;

    @include screen-xxl {
      padding-bottom: 30px; }

    @include screen-md {
      padding-bottom: 40px; }

    @include screen-sm {
      padding-bottom: 20px; } } }

