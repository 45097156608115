.benefits-grid {
  position: relative;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 48px;

    @include screen-xxl {
      padding-bottom: 40px; } }

  &__item {
    width: calc(100% / 3);
    display: flex;
    padding: 60px 50px;
    border-bottom: 1px dashed rgba(#909C9A, .5);
    border-right: 1px dashed rgba(#909C9A, .5);

    @include screen-xxl {
      padding: 48px 40px; }

    @include screen-lg {
      padding: 48px 28px; }

    @include screen-md {
      padding: 36px 30px 32px 30px;
      width: calc(100% / 2);
      flex-direction: column; }

    @include screen-sm {
      width: 100%;
      border-right: none;
      padding: 36px 0 !important;
      flex-direction: row;
      justify-content: flex-start !important; }

    &:not(.hidden) {
      opacity: 0;
      transition: opacity 600ms ease; }

    &.hidden {
      display: none;
      opacity: 0;

      &.active {
        display: flex; } }

    &:not(.hidden)[data-animated], &.animated {
      opacity: 1;

      .benefits-grid {

        &__icon {
          opacity: 1; }

        &__title span {
          transform: translateY(0); }

        &__text {
          opacity: 1;
          transform: translateY(0); } } }

    body:not(.mobile) &:hover {

      .benefits-grid {

        &__icon {

          svg {
            fill: #fff !important;
            transform: scale(1.05);
            transform-origin: left bottom; }

          &::before {
            transform: rotate(-20deg);
            opacity: 1; } }

        &__text {
          color: #a0a0a0; } } }

    &:nth-child(1) {
      border-top: 1px dashed rgba(#909C9A, .5);

      @include screen-md {
        padding-top: 0;
        border-top: none; } }

    &:nth-child(2) {
      border-top: 1px dashed rgba(#909C9A, .5);

      @include screen-md {
        padding-top: 0;
        border-top: none; } }

    &:nth-child(3) {
      border-top: 1px dashed rgba(#909C9A, .5);

      @include screen-md {
        border-top: none; } }

    &:nth-child(3n + 3) {
      @media screen and ( min-width: 1025px ) {
        border-right: none;
        padding-right: 20px; }

      @media screen and ( min-width: 1490px ) {
        padding-right: 30px; } }

    &:nth-child(3n + 1) {
      @media screen and ( min-width: 1025px ) {
        padding-left: 0; } }

    &:nth-child(odd) {
      @include screen-md {
        padding-left: 0; } }

    &:nth-child(even) {
      @include screen-md {
        border-right: none; } } }

  &__icon {
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    margin-right: 40px;
    position: relative;
    opacity: 0;
    transition: opacity 600ms ease;

    @include screen-xxl {
      width: 46px;
      height: 46px;
      margin-right: 24px; }

    @include screen-md {
      width: 48px;
      height: 48px;
      margin-right: 0;
      margin-bottom: 30px; }

    @include screen-sm {
      margin-bottom: 0;
      margin-right: 30px; }

    &::before {
      content: '';
      position: absolute;
      top: -12px;
      right: -12px;
      width: 100%;
      height: 100%;
      background: #000000;
      opacity: 0.3;
      border-radius: 12px;
      transition: opacity 150ms ease-in, transform 150ms ease-in;

      @include screen-xxl {
        top: -8px;
        right: -8px; }

      @include screen-md {
        top: -12px;
        right: -12px; } }

    svg {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: relative;
      z-index: 2;
      fill: #53F0CE !important;
      transition: all 150ms ease-in; } }

  &__caption {
    position: relative; }

  &__title {
    font-weight: 500;
    font-size: 22px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    margin-bottom: 12px;
    overflow: hidden;

    @include screen-xxl {
      font-size: 20px; }

    @include screen-md {
      font-size: 18px; }

    span {
      display: inline-block;
      transform: translateY(105%);
      transition: transform 600ms ease 300ms; } }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #767676;
    width: 100%;
    max-width: 204px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 600ms ease 450ms, transform 600ms ease 450ms, color 300ms ease;

    @include screen-xxl {
      font-size: 15px;
      max-width: 190px; }

    @include screen-md {
      max-width: 200px; }

    @include screen-sm {
      max-width: 226px; }

    a {
      @extend .link; } }

  &__more {
    display: flex;
    justify-content: center; }

  &__more-button {
    min-width: 466px;

    @include screen-xxl {
      min-width: 400px; }

    @include screen-md {
      min-width: 335px; }

    @include screen-sm {
      min-width: auto;
      width: 100%;
      max-width: 350px; } } }
