*, h1, h2, h3, h4, h5, h6, ul {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

a {
  outline: none;
  text-decoration: none; }

ul {
  list-style-type: none; }

input {
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-progress-appearance: none;

  &:active, &:focus {
    outline: none; } }

html {}

body {
  font-family: $rubik;
  background-color: #15181b;
  color: #25282A;
  min-width: 320px;
  max-width: 100%;
  font-size: 16px;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;

  &.block-scroll {
    overflow: hidden !important;

    .main {
      overflow: hidden !important; } }

  &:not(.mobile).enable-smooth-scroll {
    overflow: hidden !important;

    .main {
      overflow: hidden !important; } }

  &:not(.enable-smooth-scroll) {
    overflow: overlay; }

  &::-webkit-scrollbar {
    width: 7px;
    background-color: rgba(0, 0, 0, 0); }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0); }

  &::-webkit-scrollbar-thumb {
    background: rgba(207, 207, 207, .2);
    border-radius: 10px; } }

.inline-block {
  display: inline-block; }

.container {
  padding: 0 75px;

  @include screen-lg {
    padding: 0 48px; }

  @include screen-md {
    padding: 0 40px; }

  @include screen-sm {
    padding: 0 20px; } }

.main {
  position: relative;
  z-index: 2; }

h1, .h1 {}

h2 {
  font-weight: 400;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #F2F2F2;
  padding: 38px 0 36px 0;

  @include screen-xxl {
    font-size: 38px;
    padding: 24px 0 24px; }

  @include screen-md {
    font-size: 30px;
    padding: 24px 0 32px 0; }

  @include screen-sm {
    padding: 16px 0 32px 0; } }

h3 {
  font-weight: 400;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #F2F2F2;
  padding: 35px 0;

  @include screen-xxl {
    padding: 20px 0;
    font-size: 30px; }

  @include screen-md {
    padding: 25px 0;
    font-size: 28px;
    line-height: 130%; }

  @include screen-sm {
    padding: 20px 0 32px 0; } }

h4, .h4 {
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #F2F2F2;
  padding: 40px 0 36px;

  @include screen-xxl {
    font-size: 26px;
    padding: 20px 0 24px 0; } }

h5, .h5 {
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #F7F7F7;
  padding: 16px 0 25px 0;

  @include screen-xxl {
    padding: 4px 0 20px 0;
    font-size: 18px; } }

h6, .h6 {}

.link {
  color: #53F0CE;
  text-decoration: underline;
  text-underline-offset: 4px;

  body:not(.mobile) &:hover {
    text-decoration: none; } }

.line {
  overflow: hidden;

  &--op05 {
    opacity: .5;

    .line__inner {
      background: #535353; } }

  &__inner {
    width: 100%;
    height: 1px;
    background: #fff;
    transition: transform 600ms ease;
    transform: translateX(-101%);

    &[data-animated] {
      transform: translateX(0); } } }

.p-title {
  font-weight: 500;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #F2F2F2;
  margin-bottom: 80px;
  overflow: hidden;
  width: 100%;
  max-width: 710px;

  @include screen-xxl {
    font-size: 32px;
    margin-bottom: 60px;
    max-width: 580px; }

  @include screen-xl {
    margin-bottom: 50px; }

  @include screen-md {
    font-size: 28px;
    line-height: 130%;
    margin-bottom: 40px; }

  @include screen-sm {
    font-size: 28px;
    line-height: 120%;
    margin-bottom: 32px; }

  &--alert {
    color: #FF9F2F;
    margin-bottom: 40px;

    @include screen-xxl {
      margin-bottom: 32px; }

    @include screen-md {
      margin-bottom: 32px; }

    @include screen-sm {
      margin-bottom: 32px; }

    span {

      &::before {
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        top: 0;
        right: -40px;
        background: url('../img/p-title/icon.svg') 50% 50% no-repeat;
        background-size: contain;

        @include screen-xxl {
          width: 18px;
          height: 18px;
          right: -30px; } } } }

  span {
    position: relative;
    display: inline-block;
    transform: translateY(100%);
    transition: transform 600ms ease; }

  &[data-animated] {

    span {
      transform: translateY(0); } } }

.text-center {
  text-align: center; }

.grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &-item {
    background-color: #000;
    height: 700px; } }

.js-follow-to-cursor {

  @include screen-md {
    transform: none !important; } }

.c-scrollbar {
  z-index: 11;
  opacity: 1 !important;

  &_thumb {
    background: #CFCFCF;
    opacity: .2; } }

.yellow {
  color: #FFD600; }

.blue {
  color: #38B7FF; }

.cyan {
  color: #53F0CE; }

.m-inner {
  padding-top: 210px;
  padding-bottom: 100px;

  @include screen-xxl {
    padding-top: 190px; }

  @include screen-xl {
    padding-top: 180px; }

  @include screen-lg {
    padding-top: 150px; }

  @include screen-md {
    padding-top: 139px;
    padding-bottom: 80px; }

  @include screen-sm {
    padding-top: 102px;
    padding-bottom: 60px; } }

.p-bg {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1920px;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 0;
  transition: opacity 600ms ease;

  &[data-animated] {
    opacity: 1; }

  @include screen-md {
    min-width: auto; }

  &::before {
    content: '';
    display: inline-block;
    padding-top: 48%; }

  &--article {
    max-width: 100%;
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));

    @include screen-md {
      margin-top: 88px; }

    @include screen-sm {
      margin-top: 68px;

      &::before {
        padding-top: 90%; } } } }

.grecaptcha-badge {
    visibility: hidden !important; }
