.steps-list {
  position: relative;
  overflow: hidden;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: -40px;
    margin-right: -40px;

    @include screen-xxl {
      margin-left: -20px;
      margin-right: -20px; }

    @include screen-lg {
      margin-left: -30px;
      margin-right: -30px; }

    @include screen-sm {
      margin: 0; } }

  &__item {
    width: calc(100% / 4);
    padding: 0 40px;
    border-right: 1px dashed rgba(#909C9A, .5);
    opacity: 0;
    transition: opacity 600ms ease;

    @include screen-xxl {
      padding: 0 20px; }

    @include screen-lg {
      padding: 30px;
      width: 50%;
      border-top: 1px dashed rgba(#909C9A, .5);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &:nth-child(1) {
        padding-top: 0;
        border-top: none; }

      &:nth-child(2) {
        padding-top: 0;
        border-top: none; }

      &:nth-child(even) {
        border-right: none; } }

    @include screen-sm {
      padding: 32px 0;
      width: 100%;
      border-right: none;

      &:nth-child(2) {
        padding-top: 32px;
        border-top: 1px dashed rgba(#909C9A, .5); } }

    &:last-child {
      border-right: none;
      padding-bottom: 0; }

    &:nth-last-child(2) {
      padding-bottom: 0;

      @include screen-sm {
        padding-bottom: 32px; } }

    body:not(.mobile) &:hover {

      .steps-list {

        &__count {
          background: #0C100F; }

        &__count-value {
          color: #53F0CE; } } }

    &[data-animated] {
      opacity: 1;

      .steps-list {

        &__count {
          opacity: 1; }

        &__title, &__description {
          opacity: 1;
          transform: translateY(0); } } } }

  &__count {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #53F0CE;
    border: 2px solid #53F0CE;
    border-radius: 50%;
    margin-bottom: 40px;
    opacity: 0;
    transition: background 300ms ease, opacity 600ms ease;

    @include screen-xxl {
      width: 80px;
      height: 80px;
      margin-bottom: 30px; }

    @include screen-md {
      width: 72px;
      height: 72px;
      margin-bottom: 20px; } }

  &__count-value {
    font-weight: 500;
    font-size: 32px;
    line-height: 110%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #0C100F;
    transition: color 300ms ease;

    @include screen-xxl {
      font-size: 24px; } }

  &__title {
    font-weight: 400;
    font-size: 28px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    padding-bottom: 16px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 600ms ease 300ms, transform 600ms ease 300ms;

    @include screen-xxl {
      font-size: 20px;
      padding-bottom: 14px; }

    @include screen-md {
      font-size: 24px;
      padding-bottom: 12px; } }

  &__description {
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.02em;
    color: #CFCFCF;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 600ms ease 450ms, transform 600ms ease 450ms;

    @include screen-xxl {
      font-size: 15px; }

    a {
      @extend .link; } } }

