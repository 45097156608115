.benefits-2 {

  @include screen-sm {
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px; }

  .swiper-wrapper {

    @media only screen and (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      margin-left: -10px;
      margin-right: -10px;
      width: auto; }

    @media only screen and (min-width: 1025px) {
      margin-left: -12px;
      margin-right: -12px; }

    @media only screen and (min-width: 1490px) {
      margin-left: -15px;
      margin-right: -15px; } }

  &__item {
    width: 33.3333%;
    padding: 0 15px;
    min-height: 100%;
    height: auto;
    display: flex;
    margin-bottom: 30px;

    @include screen-xxl {
      padding: 0 12px;
      margin-bottom: 24px; }

    @include screen-md {
      width: 50%;
      padding: 0 10px;
      margin-bottom: 20px; }

    @include screen-sm {
      width: 100%;
      padding: 0;
      margin-bottom: 0; } }

  &__item-wrapper {
    background: rgba(17, 17, 17, 0.6);
    border-radius: 16px;
    padding: 30px;
    flex: 1;
    opacity: 0;
    transform: translateY(20px);
    transition: background 600ms ease, opacity 600ms ease, transform 600ms ease;

    @include screen-xl {
      padding: 24px; }

    @include screen-md {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 40px 24px; }

    body:not(.mobile) &:hover {
      background: rgba(17, 17, 17, 1);

      .benefits-2 {

        &__icon {
          transform: scale(1.05);

          &::before {
            opacity: 1; } }

        &__description {
          color: #a0a0a0; } } }

    &[data-animated] {
      opacity: 1;
      transform: translateY(0);

      .benefits-2 {

        &__icon {
          opacity: 1; }

        &__title {

          span {
            transform: translateY(0); } }

        &__description {
          opacity: 1;
          transform: translateY(0); } } } }

  &__icon {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: linear-gradient(135deg, #9EFFEA 0%, #2EE2BC 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    opacity: 0;
    transition: opacity 600ms ease, transform 300ms ease;
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      opacity: 0;
      transform: opacity 600ms ease; }

    @include screen-xxl {
      width: 60px;
      height: 60px;
      margin-bottom: 24px; }

    @include screen-md {
      width: 72px;
      height: 72px;
      margin-bottom: 32px; }

    svg {
      display: block;
      width: 32px;
      height: 32px;
      object-fit: contain;
      fill: #0C100F !important;
      transition: fill 300ms ease;
      position: relative;

      @include screen-xxl {
        width: 28px;
        height: 28px; }

      @include screen-md {
        width: 32px;
        height: 32px; } } }

  &__title {
    font-weight: 500;
    font-size: 28px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    margin-bottom: 18px;
    overflow: hidden;

    @include screen-xxl {
      font-size: 24px;
      margin-bottom: 16px; }

    @include screen-md {
      font-size: 22px;
      margin-bottom: 18px; }

    span {
      display: inline-block;
      transform: translateY(105%);
      transition: transform 600ms ease 300ms; }

    &[data-animated] {

      span {
        transform: translateY(0); } } }

  &__description {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #767676;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 600ms ease 450ms, transform 600ms ease 450ms, color 300ms ease;

    @include screen-xxl {
      font-size: 16px; }

    a {
      @extend .link; } }

  &__pagination {
    display: none;

    @include screen-sm {
      display: flex; } } }
