.fade-in {
  opacity: 0;
  transition: opacity 900ms ease-in-out;

  &.is-animated, &[data-animated] {
    opacity: 1; } }

.fade-in-up {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
  transition: opacity 600ms ease-in-out, transform 600ms ease-in-out;

  &.is-animated, &[data-animated] {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

.fade-in-right {
  opacity: 0;
  transform: translate3d(-20px, 0, 0);
  transition: opacity 600ms ease-in-out, transform 600ms ease-in-out;

  &.is-animated, &[data-animated] {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

.fade-in-left {
  opacity: 0;
  transform: translate3d(20px, 0, 0);
  transition: opacity 600ms ease-in-out, transform 600ms ease-in-out;

  &.is-animated, &[data-animated] {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

.disable-animation {
  opacity: 1 !important;
  transform: none !important; }

.full-up {
  display: inline-block;
  transform: translate3d(0, 100%, 0);
  transition: transform 600ms ease-in-out;

  &[data-animated] {
    transform: translate3d(0, 0, 0); } }


@keyframes fly {
  0% {
    transform: rotate(0deg) translate(100px) rotate(0deg) scale(1); }
  50% {
    transform: rotate(-160deg) translate(100px) rotate(160deg) scale(1.4); }
  100% {
    transform: rotate(-360deg) translate(100px) rotate(360deg) scale(1); } }
