.hidden-text {

  p {

    a {} }


  &__text {
    display: block;

    &_hidden {
      display: none; } }

  &.active {

    a {

      span {

        &:first-child {
          display: none; }

        &:last-child {
          display: block; } } } }

  a {
    display: inline-block;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: #3F505B;

    @include screen-md {
      font-size: 14px;
      line-height: 12px; }

    body:not(.mobile) &:hover {
      span {
        border-color: transparent; } }

    span {
      border-bottom: 1.5px dashed #BCCAD3;
      transition: border 300ms ease;

      &:first-child {
        display: block; }

      &:last-child {
        display: none; } } } }
