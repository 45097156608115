.m-screen {
  min-height: 100%;
  display: flex;
  flex: 1;

  @include screen-md {
    min-height: 100vh; }

  @include screen-sm {
    width: 100%;
    overflow: hidden; }

  #scene-container {
    position: absolute;
    top: 66%;
    left: 54%;
    transform: translate(-50%, -50%);
    width: 296%;
    pointer-events: none;
    z-index: 3;

    @include screen-md {
      width: 1588px;
      top: 67%;
      left: 49%; }

    @include screen-sm {
      width: 1114px;
      top: 63%;
      left: 36%; }

    &::before {
      content: '';
      display: inline-block;
      padding-top: 57.4%; } }

  canvas {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100% !important;
    height: 100% !important; }

  &__container {
    flex: 1;
    padding: 0 em(75) !important;

    @include screen-md {
      padding: 0 40px !important; }

    @include screen-sm {
      padding: 0 20px !important; } }

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: em(115) 0 0 0;
    position: relative;

    @include screen-xl {
      padding: em(40) 0 0 0; }

    @include screen-md {
      padding-top: 125px;
      padding-bottom: 60px; }

    @include screen-sm {
      padding-top: 95px;
      padding-bottom: 30px; } }

  &__line {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    overflow: hidden;

    @include screen-sm {
      display: none; } }

  &__line-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .1);
    transform: translateY(100%);
    transition: transform 600ms ease; }

  &__caption {
    max-width: em(1070);
    padding-left: em(117);
    margin-top: em(130);
    margin-bottom: em(216);
    margin-left: em(53);
    position: relative;
    z-index: 2;

    @include screen-custom(1491, 1079) {
      margin-bottom: em(146); }

    @include screen-custom(1367, 899) {
      margin-top: em(130);
      margin-bottom: em(120); }

    @include screen-custom(1281, 767) {
      margin-top: em(140);
      margin-bottom: em(90); }

    @include screen-custom(1025, 799) {
      // margin-top: em(125)
      margin-bottom: em(85); }

    @include screen-md {
      padding-left: 40px;
      margin-top: 86px;
      margin-left: 0;
      max-width: 100%;
      width: 100%;
      margin-bottom: 0; }

    @include screen-sm {
      padding-left: 0;
      border-left: none;
      margin-top: 56px;
      margin-bottom: 16px; } }

  &__star {
    width: em(88);
    height: em(88);
    position: absolute;
    left: em(113);
    top: em(-136);
    overflow: hidden;

    @include screen-md {
      width: 52px;
      height: 52px;
      left: 40px;
      top: -88px; }

    @include screen-sm {
      width: 40px;
      height: 40px;
      top: -56px;
      left: 0; }

    svg {
      width: 100%;
      height: 100%;
      display: block;
      fill: #FF9F2F;
      stroke: #FF9F2F;

      transform: translateY(100%) rotate(-180deg);
      transition: transform 600ms ease 300ms; } }

  &__title {
    font-weight: 600;
    text-transform: uppercase;
    color: #FF9F2F;
    padding-bottom: em(20);
    overflow: hidden;

    @include screen-md {
      padding-bottom: 0;
      max-width: 620px; }

    @include screen-sm {
      max-width: 320px; }

    span {
      transform: translateY(120%);
      transition: transform 600ms ease 350ms; }

    &:last-child {

      span {
        transition-delay: 500ms; } } }

  &__text {
    font-size: em(120);
    line-height: 80%;
    letter-spacing: -0.03em;
    display: inline-block;

    @include screen-xl {
      font-size: em(125); }

    @media only screen and(max-width: 1280px) {
      font-size: em(120); }

    @include screen-md {
      font-size: 64px;
      line-height: 100%; }

    @include screen-sm {
      font-size: 42px; }

    &--bordered {
      color: transparent;
      -webkit-text-stroke: 1px #FF9F2F; } }

  &__description {
    padding-top: em(20);
    max-width: em(480);

    span {
      display: inline-block;
      transform: translateY(50px);
      opacity: 0;
      transition: opacity 600ms ease 700ms, transform 600ms ease 700ms; }

    @include screen-xl {
      max-width: em(520); }

    @include screen-md {
      padding-top: 24px;
      max-width: 260px; }

    @include screen-sm {
      padding-top: 16px; }

    span {
      font-weight: 300;
      font-size: em(40);
      line-height: 120%;
      letter-spacing: -0.02em;
      color: #FFFFFF;

      @include screen-xl {
        font-size: em(42); }

      @media only screen and(max-width: 1280px) {
        font-size: em(40); }

      @include screen-md {
        font-size: 20px;
        line-height: 120%; } } }

  &__scene {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: em(740);
    width: em(970);
    height: em(1170);
    transform: translateY(-34%);

    @media only screen and (min-width: 1367px) {
      transform: translateY(-34%); }

    @include screen-custom(1281, 767) {
      transform: translateY(-28%); }

    @include screen-custom(1025, 799) {
      transform: translateY(-30%); }

    @include screen-md {
      position: relative;
      top: 0;
      left: 0;
      height: 514px;
      width: 100%;
      transform: none;
      overflow: hidden;

      // &::before
      //   content: ''
      //   display: inline-block
 }      //   padding-top: 95%

    @include screen-sm {
      width: calc(100% + 40px);
      height: 385px;
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px; }

    &-bg {
      position: absolute;
      top: em(82);
      left: em(274);
      z-index: 1;
      width: em(600);
      height: em(600);

      img {
        transform: scale(0);
        transition: transform 1.2s ease 1.2s; }

      @include screen-md {
        width: 330px;
        height: 330px;
        top: 41%;
        left: 52%;
        transform: translate(-50%, -50%); }

      @include screen-sm {
        width: 65%;
        max-width: 270px;
        height: auto;
        top: 40%;
        left: 54%; } }

    &-man {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 900ms ease 900ms;

      @include screen-md {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 125%;
        height: 125%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: 50% top; } }

      @include screen-sm {
        left: 62%; } }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain; } }

  &__btn {
    margin-left: em(53);
    opacity: 0;
    transition: opacity 600ms ease 1.5s;

    @include screen-md {
      max-width: 482px;
      width: 100%;
      margin: 0 auto;
      margin-top: -10px;
      transition-delay: 900ms; }

    @include screen-sm {
      margin-top: -12px; } }

  &__counter {
    position: absolute;
    left: em(750);
    top: em(416);

    @media only screen and (min-width: 1281px) {
      top: em(416); }

    @include screen-custom(1025, 799) {
      top: em(400); }

    @include screen-md {
      left: auto;
      top: auto;
      right: 18px;
      bottom: -74px; }

    @include screen-sm {
      right: 6px;
      bottom: -64px; } } }


[data-animated] {

  .m-screen {

    &__line-inner {
      transform: translateY(0); }

    &__star svg {
      transform: translateY(0) rotate(0); }

    &__title span {
      transform: translateY(0); }

    &__description span {
      transform: translateY(0);
      opacity: 1; }

    &__scene-man {
      opacity: 1; }

    &__scene-bg img {
      transform: scale(1); }

    &__btn {
      opacity: 1; } } }
