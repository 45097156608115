.backlink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  background: #000000;
  transition: background 300ms ease;
  flex-shrink: 0;

  @include screen-xxl {
    width: 60px;
    height: 60px; }

  @include screen-md {
    width: 50px;
    height: 50px; }

  svg {
    display: block;
    width: 40px;
    height: 40px;
    stroke: #fff;
    transition: stroke 300ms ease;

    @include screen-md {
      width: 32px;
      height: 32px; } }

  body:not(.mobile) &:hover {
    background: #53F0CE;

    svg {
      stroke: #0C100F; } } }

.backlink-container {
  display: inline-flex;
  justify-content: flex-end; }

.backlink-wrapper {
  transition: width 300ms ease;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  width: 0;

  &[data-animated] {
    width: 100%; } }
