.news-card {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: em(32);
  overflow: hidden;
  min-height: 100%;
  opacity: 0;
  transform: translateY(50px);
  transition: transform 900ms ease, opacity 900ms ease;

  &[data-animated] {
    opacity: 1;
    transform: translateY(0); }

  @include screen-md {
    padding-bottom: 40px; }

  @include screen-sm {
    padding-bottom: 32px; }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    opacity: 0.2;
    background: #CFCFCF;
    z-index: 1; }

  &::before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 4px;
    width: 100%;
    background: #53F0CE;
    z-index: 2;
    transform: translateX(-100%);
    transition: transform 300ms ease; }

  body:not(.mobile) &:hover {

    &::before {
      transform: translateX(0); }

    img {
      transform: scale(1.05); }

    .btn {

      &__name-wrapper {
        display: block;
        max-width: 100%; }

      &__bg {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1.6); }

      &__name {
        color: #0C100F; }

      &__arrow {

        svg {
          stroke: #0C100F; } } } }

  &--inner {

    .news-card {

      &__header {

        @include screen-xxl {
          padding-bottom: 24px; }

        @include screen-md {
          padding-bottom: 40px; }

        @include screen-sm {
          padding-bottom: 32px; } }

      &__date {

        @include screen-xxl {
          font-size: 24px; }

        @include screen-md {
          font-size: 24px; } }

      &__image {

        @include screen-xxl {
          margin-bottom: 30px; }

        @include screen-md {
          margin-bottom: 24px; } }

      &__title {

        @include screen-xxl {
          line-height: 33px;
          min-height: calc(66px + 16px); }

        @include screen-md {
          line-height: 35px;
          min-height: calc(70px + 16px);
          padding-bottom: 16px; }

        span {

          @include screen-xxl {
            font-size: 30px; }

          @include screen-md {
            font-size: 32px;
            -webkit-line-clamp: 3; }

          @include screen-sm {
            -webkit-line-clamp: unset; } } }

      &__description {
        padding-bottom: 24px;

        @include screen-md {
          padding-bottom: 40px; }

        @include screen-sm {
          padding-bottom: 32px; }

        span {

          @include screen-xxl {
            font-size: 12px; }

          @include screen-md {
            font-size: 16px;
            line-height: 140%;
            -webkit-line-clamp: 3; } } } } }

  &__header {
    padding-bottom: em(32);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: em(16);

    @include screen-md {
      padding-bottom: 40px;
      gap: 16px; }

    @include screen-sm {
      padding-bottom: 32px;
      gap: 12px; } }

  &__date {
    font-family: $bebas;
    text-transform: uppercase;
    font-size: em(32);
    line-height: 100%;
    color: #FFFFFF;

    @include screen-md {
      font-size: 24px; }

    span {
      color: #CFCFCF;
      opacity: .4; } }

  &__ext-link {
    position: relative;
    display: inline-block;
    padding-right: em(24);

    @include screen-md {
      padding-right: 24px; }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: em(18);
      height: em(18);
      background-image: url('../img/news-card/arrow.svg');
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;

      @include screen-md {
        width: 18px;
        height: 18px; } }

    span {
      color: #FCFCFC;
      font-size: em(16);
      font-weight: 400;
      line-height: 140%;
      letter-spacing: -0.32px;

      @include screen-md {
        font-size: 14px;
        letter-spacing: -0.28px; } } }

  &__badge {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    padding: em(4) em(12);
    background: #FF9F2F;

    @include screen-md {
      padding: 3px 10px; }

    span {
      color: #0C100F;
      font-size: em(14);
      font-weight: 600;
      line-height: 100%;
      letter-spacing: -0.28px;
      text-transform: uppercase;

      @include screen-md {
        font-size: 12px;
        letter-spacing: -0.24px; } } }

  &__image {
    position: relative;
    margin-bottom: em(40);
    overflow: hidden;

    @include screen-md {
      margin-bottom: 24px; }

    &::before {
      content: '';
      display: inline-block;
      padding-top: 54.7%;

      @include screen-md {
        padding-top: 54%; } }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 1200ms ease;
      z-index: 1; } }

  &__caption {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start; }

  &__title {
    position: relative;
    padding-bottom: em(16);
    min-height: em(88 + 16); // todo
    line-height: em(44);
    display: block;

    @include screen-md {
      line-height: 35px;
      min-height: calc(70px + 16px);
      padding-bottom: 16px; }

    span {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-weight: 500;
      font-size: em(40);
      letter-spacing: -0.02em;
      color: #F2F2F2;

      @include screen-md {
        font-size: 32px;
        -webkit-line-clamp: 3; }

      @include screen-sm {
        -webkit-line-clamp: unset; } } }

  &__description {
    display: block;
    padding-bottom: em(32);

    @include screen-md {
      padding-bottom: 40px; }

    @include screen-sm {
      padding-bottom: 32px; }

    span {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-weight: 300;
      font-size: em(18);
      line-height: 140%;
      letter-spacing: -0.02em;
      color: #CFCFCF;

      @include screen-md {
        font-size: 16px;
        line-height: 140%;
        -webkit-line-clamp: 3; } } }

  &__btn {
    margin-top: auto; } }
