.swiper-navigation {
  position: relative;
  display: flex;
  align-items: center;

  @include screen-md {
    display: none; }

  &--news {

    @include screen-md {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      justify-content: space-between;

      .swiper-navigation {

        &__btn {}

        &__circle {
          display: none; } } }

    @include screen-sm {
      display: none; } }

  &__btn {
    width: em(64);
    height: em(64);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 300ms ease-out;
    border-radius: 50%;
    overflow: hidden;

    body:not(.mobile) &:hover:not(.swiper-button-disabled) {
      @media only screen and (min-width: 1025px) {
        background: #53F0CE;

        .swiper-navigation {

          &__icon svg {
            stroke: #0C100F !important; } } } }

    &--prev {
      margin-right: em(16);

      .swiper-navigation {

        &__icon {
          transform: scale(-1, 1); } } }

    &--next {}

    &.swiper-button-disabled {

      .swiper-navigation {

        &__circle {
          opacity: 0; }

        &__icon svg {
          stroke: #E3E3E3; } } } }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    transition: stroke 300ms ease; }

  &__circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: opacity 300ms ease;

    svg {
      stroke: #53F0CE; } }

  &__icon {
    width: em(24);
    height: em(24);
    position: relative;
    z-index: 2;

    svg {
      stroke: #53F0CE; } } }

.js-animate {

  .swiper-navigation {

    &__circle svg {
      stroke-dasharray: 195;
      stroke-dashoffset: 195;
      transition: all 900ms ease 1200ms; }

    &__icon {
      opacity: 0;
      transition: opacity 900ms ease 1400ms; } }

  &[data-animated] {

    .swiper-navigation {

      &__circle svg {
        stroke-dasharray: 390;
        stroke-dashoffset: 195; }

      &__icon {
        opacity: 1; } } } }
