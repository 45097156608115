.f-slider {

  @include screen-md {
    overflow: visible;
    padding-bottom: 46px; }

  .swiper-wrapper {

    @media only screen and (min-width: 1025px) {
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;

      @include screen-xxl {
        margin-left: -10px;
        margin-right: -10px; } } }

  &__item {
    width: calc(100% / 3);
    padding: 0 15px 30px 15px;
    height: auto;

    @include screen-xxl {
      padding: 0 10px 20px 10px; }

    @include screen-md {
      width: 230px;
      padding: 0; }

    body:not(.mobile) &:hover {

      .f-slider {

        &__wrapper {
          background: rgba(17, 17, 17, 1); }

        &__icon {
          transform: scale(1.05);

          svg {
            fill: #fff;

            &.stroke {
              stroke: #fff; } } }

        &__title {
          color: #53F0CE; }

        &__description {
          color: #a0a0a0; } } } }

  &__wrapper {
    padding: 40px 30px;
    display: flex;
    background: rgba(17, 17, 17, 0.6);
    border-radius: 16px;
    height: 100%;
    opacity: 0;
    transition: background 300ms ease, opacity 300ms ease;

    @include screen-xxl {
      padding: 30px 20px; }

    @include screen-lg {
      flex-direction: column; }

    @include screen-md {
      padding: 32px 24px; }

    &[data-animated] {
      opacity: 1;

      .f-slider {

        &__icon {
          opacity: 1; }

        &__title span {
          transform: translateY(0); }

        &__description {
          opacity: 1;
          transform: translateY(0); } } } }

  &__icon {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    margin-right: 40px;
    opacity: 0;
    transition: transform 300ms ease, opacity 600ms ease;

    @include screen-xxl {
      width: 48px;
      height: 48px;
      margin-right: 24px; }

    @include screen-lg {
      margin-right: 0;
      margin-bottom: 24px; }

    @include screen-md {
      width: 60px;
      height: 60px; }

    svg {
      display: block;
      width: 100%;
      height: 100%;
      fill: #53F0CE;
      transition: fill 300ms ease;

      &.stroke {
        fill: none !important;
        stroke: #53F0CE;
        transition: stroke 300ms ease; } } }

  &__caption {
    position: relative; }

  &__title {
    font-family: $bebas;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 100%;
    color: #FFFFFF;
    margin-bottom: 18px;
    transition: color 300ms ease;
    overflow: hidden;

    span {
      display: inline-block;
      transform: translateY(105%);
      transition: transform 600ms ease 300ms; }

    @include screen-xxl {
      font-size: 32px;
      margin-bottom: 12px; }

    @include screen-md {
      font-size: 40px; } }

  &__description {
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #767676;
    opacity: 0;
    transform: translateY(20px);
    transition: color 300ms ease, opacity 600ms ease 450ms, transform 600ms ease 450ms;

    @include screen-xxl {
      font-size: 14px; }

    @include screen-md {
      font-size: 15px; }

    a {
      @extend .link; } }

  .swiper-scrollbar {
    background: rgba(#D9D9D9, 0.08) !important;
    border-radius: 20px !important;

    &-drag {
      background:#D9D9D9 !important {}
      opacity: 0.3 !important; } } }
