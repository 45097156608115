.values {

  &__row {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;

    @include screen-xxl {
      margin-left: -10px;
      margin-right: -10px; } }

  &__col {
    width: 50%;
    padding: 0 15px;

    @include screen-xxl {
      padding: 0 10px; }

    @include screen-sm {
      width: 100%; } }

  &__item {
    padding-bottom: 100px;
    display: flex;

    @include screen-xxl {
      padding-bottom: 60px; }

    @include screen-md {
      flex-direction: column;
      padding-bottom: 40px; }

    &[data-animated] {

      .values {

        &__icon {
          opacity: 1; }

        &__title span {
          transform: translateY(0); }

        &__description {
          opacity: 1;
          transform: translateY(0); } } }

    body:not(.mobile) &:hover {

      .values {

        &__icon {

          svg {
            fill: #fff !important;
            transform: scale(1.05);
            transform-origin: left bottom; }

          &::before {
            transform: rotate(-20deg);
            opacity: 1; } }

        &__description {
          color: #a0a0a0; } } } }

  &__icon {
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    margin-right: 40px;
    position: relative;
    opacity: 0;
    transition: opacity 600ms ease;

    @include screen-xxl {
      width: 46px;
      height: 46px;
      margin-right: 24px; }

    @include screen-md {
      width: 48px;
      height: 48px;
      margin-right: 0;
      margin-bottom: 30px; }

    &::before {
      content: '';
      position: absolute;
      top: -12px;
      right: -12px;
      width: 100%;
      height: 100%;
      background: #000000;
      opacity: 0.3;
      border-radius: 12px;
      transition: opacity 150ms ease-in, transform 150ms ease-in;

      @include screen-xxl {
        top: -8px;
        right: -8px; }

      @include screen-md {
        top: -12px;
        right: -12px; } }

    svg {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: relative;
      z-index: 2;
      fill: #53F0CE !important;
      transition: all 150ms ease-in; } }

  &__caption {
    width: 100%;
    max-width: 395px;

    @include screen-xxl {
      max-width: 340px; }

    @include screen-md {
      max-width: 260px; }

    @include screen-sm {
      max-width: 100%; } }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    margin-bottom: 24px;
    overflow: hidden;

    span {
      display: inline-block;
      transform: translateY(105%);
      transition: transform 600ms ease 300ms; }

    @include screen-xxl {
      font-size: 22px;
      margin-bottom: 18px; }

    @include screen-md {
      font-size: 18px;
      line-height: 130%;
      margin-bottom: 12px; } }

  &__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #767676;
    opacity: 0;
    transform: translateY(20px);
    transition: color 300ms ease, opacity 600ms ease 450ms, transform 600ms ease 450ms;

    @include screen-xxl {
      font-size: 15px; }

    a {
      @extend .link; } } }
