.footer {
  width: 100%;

  &[data-animated] {

    .footer {

      &__wrapper::before {
        transform: translateX(0); }

      &__logo {
        opacity: 1; }

      &__copy, &__socials, &__nav, &__form, &__develop {
        opacity: 1;
        transform: translateY(0); } } }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: em(80) 0 em(80) 0;
    overflow: hidden;

    @include screen-xxl {
      padding: 70px 0; }

    @include screen-xl {
      padding: 60px 0; }

    @include screen-lg {
      padding: 60px 0;
      flex-wrap: wrap; }

    @include screen-md {
      padding: 50px 0; }

    @include screen-sm {
      padding: 24px 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start; }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #fff;
      transform: translateX(-100%);
      transition: transform 900ms ease 300ms; } }

  &__left {

    @include screen-lg {
      width: 40%;
      padding-bottom: 30px; }

    @include screen-md {
      padding-bottom: 32px;
      width: 42%; }

    @include screen-sm {
      width: 100%;
      padding-bottom: 25px; } }

  &__logo {
    display: block;
    width: 194px;
    margin-bottom: 52px;
    opacity: 0;
    transition: opacity 900ms ease;

    @include screen-xxl {
      margin-bottom: 40px; }

    @include screen-xl {
      width: 160px;
      margin-bottom: 30px; }

    @include screen-lg {
      width: 194px;
      margin-bottom: 30px; }

    @include screen-md {
      width: 133px;
      margin-bottom: 40px; }

    @include screen-sm {
      width: 133px;
      height: 44px;
      margin-bottom: 40px; }

    img {
      display: block;
      width: 100%;
      height: auto; } }

  &__copy {
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.04em;
    color: #CFCFCF;
    margin-bottom: 64px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 900ms ease 600ms, transform 900ms ease 600ms;

    @include screen-xxl {
      margin-bottom: 78px; }

    @include screen-xl {
      font-size: 15px;
      margin-bottom: 60px; }

    @include screen-lg {
      font-size: 16px;
      margin-bottom: 24px; }

    @include screen-md {
      font-size: 14px;
      line-height: 150%;
      margin-bottom: 20px; }

    @include screen-sm {
      font-size: 14px;
      line-height: 140%;
      margin-bottom: 16px; }

    &--desktop {
      display: block;

      @include screen-sm {
        display: none; } }

    &--mobile {
      display: none;

      @include screen-sm {
        display: block; } } }

  &__socials {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left:  -7px;
    margin-right: -7px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 900ms ease 600ms, transform 900ms ease 600ms;

    @include screen-xl {
      margin-left: -5px;
      margin-right: -5px; }

    @include screen-lg {
      margin-left:  -7px;
      margin-right: -7px; }

    @include screen-md {
      margin-left: -4px;
      margin-right: -4px; }

    @include screen-sm {
      margin-left: -5px;
      margin-right: -5px; } }

  &__social {
    display: block;
    padding: 0 7px 14px 7px;
    flex-shrink: 0;

    @include screen-xl {
      padding: 0 5px 10px 5px; }

    @include screen-lg {
      padding: 0 7px 14px 7px; }

    @include screen-md {
      padding: 0 4px 8px 4px; }

    @include screen-sm {
      padding: 0 5px 10px 5px; }

    body:not(.mobile) &:hover {

      .footer__social-icon svg {
        fill: #53F0CE; } } }

  &__social-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: #0C0C0C;
    border-radius: 12px;

    @include screen-xl {
      width: 42px;
      height: 42px;
      border-radius: 8px; }

    @include screen-lg {
      width: 48px;
      height: 48px; }

    @include screen-md {
      width: 40px;
      height: 40px; }

    @include screen-sm {
      width: 40px;
      height: 40px; }

    svg {
      display: block;
      width: 32px;
      height: 32px;
      fill: #fff;
      transition: fill 300ms ease;

      @include screen-xl {
        width: 28px;
        height: 28px; }

      @include screen-lg {
        width: 32px;
        height: 32px; }

      @include screen-md {
        width: 24px;
        height: 24px; }

      @include screen-sm {
        width: 24px;
        height: 24px; } } }

  &__nav {
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 12px 40px 0 40px;
    width: 100%;
    max-width: 620px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 900ms ease 600ms, transform 900ms ease 600ms;

    @include screen-xxl {
      max-width: 560px;
      padding: 12px 30px 0 30px; }

    @include screen-xl {
      padding: 12px 40px 0 40px; }

    @include screen-lg {
      padding: 12px 24px 0 24px; }

    @include screen-md {
      width: 58%;
      padding: 12px 0 0 24px; }

    @include screen-sm {
      width: 100%;
      max-width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0;
      padding-bottom: 35px;
      border-bottom: 2px solid rgba(#CFCFCF, .2);
      margin-bottom: 40px; } }

  &__nav-col {
    width: auto;

    @include screen-lg {
      width: auto; }

    @include screen-sm {
      width: 100%; } }

  &__nav-item {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #CFCFCF;
    padding: 12px 20px;
    white-space: nowrap;

    transition: color 300ms ease;

    @include screen-xl {
      font-size: 15px;
      padding: 10px 16px; }

    @include screen-lg {
      font-size: 16px;
      padding: 12px 20px; }

    @include screen-md {
      font-size: 15px;
      padding: 12px 16px; }

    body:not(.mobile) &:hover {
      color: #53F0CE;

      .yellow {
        color: #53F0CE; } }

    .yellow {
      transition: color 300ms ease; }

    @include screen-sm {
      font-size: 16px;
      padding: 9px 0; } }

  &__right {
    flex-shrink: 0;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include screen-xxl {
      padding-top: 8px; }

    @include screen-lg {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-top: 0; }

    @include screen-sm {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center; } }

  &__form {
    padding-bottom: 91px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 900ms ease 600ms, transform 900ms ease 600ms;

    @include screen-xxl {
      padding-bottom: 100px; }

    @include screen-xl {
      padding-bottom: 72px; }

    @include screen-lg {
      padding-bottom: 0; }

    @include screen-sm {
      padding-bottom: 40px;
      width: 100%; } }

  &__form-title {
    opacity: 0.6;
    padding-bottom: 20px;
    color: #CFCFCF;
    font-size: 16px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.02em;

    @include screen-xl {
      font-size: 15px;
      padding-bottom: 16px; }

    @include screen-lg {
      font-size: 16px;
      padding-bottom: 20px; }

    @include screen-md {
      padding-bottom: 12px; } }

  &__develop {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 900ms ease 600ms, transform 900ms ease 600ms;

    @include screen-lg {
      padding-top: 30px; }

    @include screen-sm {
      padding-top: 0; } } }


