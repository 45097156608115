.mobile {

  .custom-cursor {
    display: none; } }

.custom-cursor {
  position: fixed;
  top: -37px;
  left: -37px;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  z-index: 11;
  background: transparent;
  pointer-events: none;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @include screen-md {
    display: none !important; }

  &.hide {
    opacity: 0 !important;
    visibility: hidden; }

  &.prev-mode {

    .custom-cursor {

      &__item {
        background: #fff; }

      &__icon {

        &--next {
          display: none; }

        &--prev {
          display: block; }

        &--crosshair {
          display: none; }

        &--play {
          display: none !important; } }

      &__text {
        display: none !important; } } }

  &.next-mode {

    .custom-cursor {

      &__item {
        background: #fff; }

      &__icon {

        &--prev {
          display: none; }

        &--next {
          display: block; }

        &--crosshair {
          display: none; }

        &--play {
          display: none !important; } }

      &__text {
        display: none !important; } } }

  &.video-mode {

    .custom-cursor {

      &__item {
        background: #fff; }

      &__icon {

        &--crosshair {
          display: none; }

        &--play {
          display: block !important; } }

      &__text {
        display: none !important; } } }

  &.drag-mode {
    .custom-cursor {

      &__item {
        background: #fff; }

      &__icon {

        &--crosshair {
          display: none; }

        &--play {
          display: none; } }

      &__text {
        display: block; } } }

  &__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    z-index: 3;

    &--shadow-top {
      z-index: 2;
      opacity: 0.6; }

    &--shadow-bottom {
      z-index: 1;
      opacity: 0.2; } }

  &__content {
    position: relative;
    z-index: 4; }

  &__icon {
    position: relative;

    &--crosshair {
      width: 30px;
      height: 30px;
      background: url('../img/custom-cursor/icon.svg') 50% 50% no-repeat;
      background-size: contain;
      display: block; }

    &--play {
      width: 20px;
      height: 20px;
      background: url('../img/custom-cursor/play.svg') 50% 50% no-repeat;
      background-size: contain;
      display: none; }

    &--prev {
      width: 28px;
      height: 28px;
      background: url('../img/custom-cursor/prev.svg') 50% 50% no-repeat;
      background-size: contain;
      display: none; }

    &--next {
      width: 28px;
      height: 28px;
      background: url('../img/custom-cursor/next.svg') 50% 50% no-repeat;
      background-size: contain;
      display: none; } }

  &__text {
    font-size: 15px;
    line-height: 1;
    z-index: 4;
    color: #000;
    display: none;
    text-align: center;
    white-space: nowrap; } }

@media only screen and (min-width: 1025px) {

  .js-custom-cursor * {
    cursor: none !important; } }
