.home {

  .m-navigation {
    display: flex; } }

.m-section {

  &--news {

    @include screen-md {
      margin-top: -136px; }

    @include screen-sm {
      margin-top: -148px; }

    .m-section {

      &__content {
        min-height: auto;
        padding-top: 0 !important; } } }

  .container:not(.container--fluid) {
    width: em(1440) !important;
    max-width: em(1440) !important;
    margin: 0 auto;
    padding: 0 !important;

    @include screen-md {
      width: 100% !important;
      max-width: none !important;
      padding: 0 40px !important; }

    @include screen-sm {
      padding: 0 20px !important; } }

  &__content {
    min-height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
    position: relative;
    padding: 115px 0 80px;
    overflow: hidden;
    display: flex;

    // &:last-child
    //   width: 90vw

    @include screen-md {
      display: block;
      min-height: auto;
      width: 100% !important;
      padding: 0; }

    &--main {
      padding: 120px 0 80px;

      @include screen-xxl {
        padding: 94px 0 70px; }

      @media only screen and (max-width: 1280px) {
        padding: 115px 0 70px; } } } }

.m-header {
  position: relative;
  padding-bottom: em(80);

  @include screen-md {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 40px; }

  &--inner {
    padding-bottom: 0;

    @include screen-md {
      padding-bottom: 0;
      display: block; }

    .m-header {

      &__wrapper {
        overflow: hidden; } } }

  &[data-animated], &.active {

    .m-header {

      &__title span {
        transform: translateX(0);

        @include screen-sm {
          transform: translateY(0);
          opacity: 1; } }

      &__row::before, &__wrapper::before {
        transform: translateX(0) !important; } } }

  &__wrapper {
    position: relative;
    padding-bottom: 80px;

    @include screen-xxl {
      padding-bottom: 48px; }

    @include screen-xl {
      padding-bottom: 40px; }

    @include screen-sm {
      padding-bottom: 32px; }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #fff;
      transform: translateX(-100%);
      transition: transform 900ms ease 300ms; } }

  &--news {
    padding-bottom: 0 !important;
    overflow: hidden;

    .m-header__row {
      margin-left: em(-45);
      margin-right: em(-45);
      padding: 0 em(45) em(50) em(45);
      position: relative;

      @include screen-md {
        padding: 0 0 50px 0;
        margin: 0; }

      @include screen-sm {
        padding-bottom: 40px; }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #fff;
        transform: translateX(-100%);
        transition: transform 900ms ease 300ms; } } }

  &__row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    overflow: hidden;

    @include screen-md {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start; } }

  &__col {
    padding-right: em(16);

    @include screen-md {
      padding: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center; } }

  &__badge {
    padding-bottom: em(48);
    position: relative;

    @include screen-md {
      padding-bottom: 24px; } }

  &__title {
    font-weight: 500;
    font-size: em(80);
    line-height: 120%;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    color: #FFFFFF;
    overflow: hidden;
    display: inline-block;
    overflow: hidden;

    @include screen-sm {
      overflow: visible; }

    span {
      display: inline-block;
      transform: translateX(100%);
      transition: transform 900ms ease 300ms, opacity 900ms ease 300ms;

      @include screen-sm {
        transform: translateY(20px);
        opacity: 0; } }

    @include screen-md {
      font-size: 40px; }

    &--inner {
      font-size: 72px;

      @include screen-xxl {
        font-size: 52px; }

      @include screen-xl {
        font-size: 48px; }

      @include screen-md {
        font-size: 36px;
        line-height: 110%;
        text-align: center; } } } }

.horizontal-scroll {
  overflow: hidden;

  &__scroll {
    display: flex;

    .m-section {
      width: 100vw;

      @include screen-md {
        width: 100%; } }

    @include screen-md {
      display: block;
      width: auto; } } }

