.blockquote-3 {
  font-weight: 400;
  font-size: 30px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #F2F2F2;
  position: relative;
  padding-left: 64px;

  @include screen-xxl {
    font-size: 24px;
    padding-left: 40px; }

  @include screen-md {
    padding-left: 24px;
    font-size: 18px; }

  &::before {
    content: '';
    width: 6px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #FF9F2F;

    @include screen-xxl {
      width: 4px; }

    @include screen-md {
      width: 3px; } }

  a {
    @extend .link; } }
