.vacancy-response {
  position: sticky;
  bottom: 0;
  background: linear-gradient(180deg, rgba(17, 17, 17, 0.9) 0%, rgba(23, 23, 23, 0.9) 100%);
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease;

  &.active {
    opacity: 1;
    visibility: visible; }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0;

    @include screen-xxl {
      padding: 18px 24px; }

    @include screen-md {
      padding: 16px 16px; }

    @include screen-sm {
      padding: 16px 0; } }

  &__btn {

    @include screen-sm {
      width: 100%; } }

  &__share {

    @include screen-sm {
      display: none; } } }
