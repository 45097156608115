.about {

  &__row {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;

    @include screen-md {
      flex-direction: column; } }

  &__col {
    width: 50%;
    padding: 0 10px;

    @include screen-md {
      width: 100%;

      &:first-child {
        order: 2; }

      &:last-child {
        order: 1; } } }

  &__article {

    h2 {
      @extend .p-title;
      margin-bottom: 40px !important;

      @include screen-xxl {
        margin-bottom: 32px !important; }

      @include screen-md {
        margin-bottom: 32px !important; }

      @include screen-sm {
        margin-bottom: 32px !important; } }

    a {
      @extend .link; }

    p {
      font-weight: 300;
      font-size: 20px;
      line-height: 160%;
      letter-spacing: -0.02em;
      color: #CFCFCF;
      padding-bottom: 32px;

      @include screen-xxl {
        font-size: 18px;
        padding-bottom: 24px; }

      @include screen-md {
        font-size: 16px; } } }

  &__gallery {
    padding-left: 88px;

    @include screen-xxl {
      padding-left: 60px; }

    @include screen-lg {
      padding-left: 20px; }

    @include screen-md {
      padding-left: 0;
      padding-bottom: 60px; }

    @include screen-sm {
      padding-bottom: 40px; } }

  &__gallery-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;

    @include screen-xxl {
      grid-gap: 20px; }

    @include screen-md {
      grid-template-columns: 1.58fr 1fr; }

    @include screen-sm {
      grid-template-columns: 1fr 1fr; } }

  &__gallery-item {

    &:first-child {
      width: 100%;
      grid-column-start: 1;
      grid-column-end: 3;

      @include screen-md {
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3; }

      @include screen-sm {
        grid-column-end: 3;
        grid-row-end: 2; }

      .about__gallery-image {
        border-radius: 20px;

        &::before {
          padding-top: 100% !important; } } } }

  &__gallery-image {
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    @include screen-md {
      height: 100%; }

    &::before {
      content: '';
      display: inline-block;
      padding-top: 67.5%;

      @include screen-md {
        padding-top: 0; }

      @include screen-sm {
        padding-top: 67.5%; } }

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; } } }
