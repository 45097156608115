.share {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 0 6px;

  @include screen-md {
    padding: 0;
    flex-direction: row; }

  &--s-horizontal {
    flex-direction: row;

    .share {

      &__hint {
        left: 50%;
        top: 0;
        transform: translate(-50%, -100%); }

      &__title {
        padding-bottom: 0 !important;
        padding-right: 14px;
        font-size: 16px;

        @include screen-xxl {
          font-size: 15px;
          padding-right: 16px; }

        @include screen-md {
          font-size: 14px;
          padding-right: 7px; } }

      &__item {
        padding: 6px;

        @include screen-md {
          padding: 5px; }

        svg {

          @include screen-md {
            fill: #CFCFCF;

            &.stroke {
              fill: none;
              stroke: #CFCFCF; } } }

        body:not(.mobile) &:hover, &.active {

          .share {

            &__item-container {
              border-color: #F2F2F2;
              background: #F2F2F2; } }

          svg {
            fill: #0C100F;

            &.stroke {
              stroke: #0C100F; } } } }

      &__item-container {

        @include screen-md {
          border-color: #CFCFCF;
          background: transparent; } } }

    .ssbp-wrap {

      .ssbp-list {
        flex-direction: row !important; } } }

  &--horizontal {
    flex-direction: row;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    border-radius: 20px;
    padding: 18px 24px;
    justify-content: center;

    @include screen-md {
      padding: 9px 24px;
      border-radius: 12px; }

    .share {

      &__hint {
        left: 50%;
        top: 0;
        transform: translate(-50%, -100%); }

      &__title {
        padding-bottom: 0 !important;
        padding-right: 24px;
        font-size: 20px;

        @include screen-xxl {
          font-size: 18px;
          padding-right: 16px; }

        @include screen-md {
          font-size: 14px;
          padding-right: 7px; } }

      &__item {
        padding: 6px;

        @include screen-md {
          padding: 5px; }

        svg {

          @include screen-md {
            fill: #CFCFCF;

            &.stroke {
              fill: none;
              stroke: #CFCFCF; } } }

        body:not(.mobile) &:hover, &.active {

          .share {

            &__item-container {
              border-color: #F2F2F2;
              background: #F2F2F2; } }

          svg {
            fill: #0C100F;

            &.stroke {
              stroke: #0C100F; } } } }

      &__item-container {

        @include screen-md {
          border-color: #CFCFCF;
          background: transparent; } } }

    .ssbp-wrap {

      .ssbp-list {
        flex-direction: row !important; } } }

  &__title {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    opacity: 0.5;
    text-align: center;
    padding-bottom: 8px;

    @include screen-md {
      padding-bottom: 0;
      padding-right: 7px; } }

  &__item {
    padding: 6px;
    box-sizing: content-box;
    flex-shrink: 0;
    position: relative;

    @include screen-xxl {
      padding: 6px; }

    @include screen-md {
      padding: 5px; }

    svg {
      display: block;
      width: 24px;
      height: 24px;
      fill: #CFCFCF;
      transition: fill 300ms ease;

      @include screen-xxl {
        width: 20px;
        height: 20px; }

      @include screen-md {
        width: 20px;
        height: 20px; }

      &.stroke {
        fill: none !important;
        stroke: #CFCFCF;
        transition: stroke 300ms ease; } }

    &.active {

      .share__hint {
        opacity: 1;
        visibility: visible; } }

    body:not(.mobile) &:hover, &.active {

      .share {

        &__item-container {
          border-color: #F2F2F2;
          background: #F2F2F2; } }

      svg {
        fill: #0C100F;

        &.stroke {
          stroke: #0C100F; } } } }

  &__item-container {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid #CFCFCF;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: border 300ms ease, background 300ms ease;

    @include screen-xxl {
      width: 40px;
      height: 40px; }

    @include screen-md {
      width: 36px;
      height: 36px; } }

  &__hint {
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-100%, -50%);
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: #53F0CE;
    padding: 8px 18px;
    display: block;
    background: #0C100F;
    box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: all 300ms ease;
    z-index: 10;

    @include screen-xxl {
      font-size: 13px;
      padding: 6px 12px; }

    @include screen-md {
      left: 50%;
      top: 0;
      transform: translate(-50%, -70%); } }


  .ssba-share-text {
    display: none !important; }

  .ssbp-wrap {

    .ssbp-list {
      display: flex;
      flex-direction: column;

      @include screen-md {
        flex-direction: row; }

      .ssbp-li--twitter {

        svg {

          rect {
            fill: none !important; } }

        a {

          body:not(.mobile) &:hover, &.active {

            svg {

              path {
                fill: #0C100F !important; } } } } }

      li {
        margin: 0 !important;

        a {
          padding: 6px;
          box-sizing: content-box;
          background: none !important;
          border: none !important;
          width: auto !important;
          height: auto !important;

          @include screen-xxl {
            padding: 6px; }

          @include screen-md {
            padding: 5px; }

          .color-icon {
            display: none !important; }

          span:not(.color-icon) {
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            overflow: hidden;
            border: 2px solid #CFCFCF;
            background: transparent;
            transition: border 300ms ease, background 300ms ease;

            @include screen-xxl {
              width: 40px;
              height: 40px; }

            @include screen-md {
              width: 36px;
              height: 36px; }

            svg {
              width: 24px;
              height: 24px;
              display: block;
              fill: #CFCFCF;
              transition: fill 300ms ease;
              line-height: normal !important;

              @include screen-xxl {
                width: 20px;
                height: 20px; } } }

          body:not(.mobile) &:hover, &.active {

            span:not(.color-icon) {
              background: #F2F2F2;
              border-color: #F2F2F2;

              svg {
                fill: #0C100F !important; } } } } } } } }

