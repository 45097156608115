.benefits-3 {
  overflow: hidden;

  @include screen-md {
    background: rgba(0, 0, 0, 0.4); }

  &__container {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    padding: 60px 40px;

    @include screen-xxl {
      padding: 40px 30px; }

    @include screen-md {
      background: none;
      padding: 32px 20px;
      margin-left: -20px;
      margin-right: -20px; }

    @include screen-sm {
      padding: 48px 0;
      margin: 0;
      overflow: visible; } }

  &__item {
    display: inline-flex;
    justify-content: center;

    @include screen-sm {
      width: auto !important; } }

  &__value {
    font-family: $bebas;
    text-transform: uppercase;
    font-size: 80px;
    line-height: 100%;
    letter-spacing: -0.05em;
    color: #53F0CE;
    padding-bottom: 8px;

    @include screen-xxl {
      font-size: 64px; }

    @include screen-md {
      font-size: 50px; } }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #CFCFCF;

    @include screen-xxl {
      font-size: 13px; }

    @include screen-md {
      font-size: 12px; } }

  .swiper-scrollbar {
    bottom: 16px;
    height: 6px;
    background: rgba(#D9D9D9, 0.08) !important;
    border-radius: 20px !important;

    &-drag {
      background:#D9D9D9 !important {}
      opacity: 0.3 !important; } } }
