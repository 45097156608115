.error-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: 724px;
    margin: 0 auto;
    position: relative;
    padding: 137px 0;

    @include screen-xxl {
      max-width: 600px;
      padding: 114px 0; }

    @include screen-xl {
      padding: 90px 0;
      max-width: 550px; }

    @include screen-md {
      padding: 64px 0;
      max-width: 400px; }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 1059px;
      height: 463px;
      transform: translateX(-50%);
      background: url('../img/error/bullets.svg') 50% 50% no-repeat;
      background-size: contain;

      @include screen-xxl {
        width: 847px;
        height: 370px; }

      @include screen-xl {
        top: -60px; }

      @include screen-md {
        width: 483px;
        height: 174px;
        top: 0;
        background: url('../img/error/bullets-md.svg') 50% 50% no-repeat;
        background-size: contain; }

      @include screen-sm {
        width: 319px;
        height: 149px;
        background: url('../img/error/bullets-sm.svg') 50% 50% no-repeat;
        background-size: contain; } } }

  &__code {
    font-weight: 300;
    font-size: 180px;
    line-height: 80%;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-bottom: 64px;

    @include screen-xxl {
      font-size: 140px;
      padding-bottom: 48px; }

    @include screen-xl {
      font-size: 120px;
      padding-bottom: 40px; }

    @include screen-md {
      font-size: 80px;
      padding-bottom: 48px; } }

  &__title {
    font-weight: 500;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    padding-bottom: 40px;

    @include screen-xxl {
      font-size: 36px;
      padding-bottom: 32px; }

    @include screen-xl {
      font-size: 32px; }

    @include screen-md {
      font-size: 22px;
      line-height: 130%;
      padding-bottom: 20px; } }

  &__description {
    font-weight: 300;
    font-size: 24px;
    line-height: 140%;
    color: #F2F2F2;

    @include screen-xxl {
      font-size: 20px; }

    @include screen-xl {
      font-size: 18px; }

    @include screen-md {
      font-size: 16px; } }

  &__buttons {
    padding-top: 90px;
    display: flex;
    justify-content: center;
    width: 100%;

    @include screen-xxl {
      padding-top: 60px; }

    @include screen-sm {
      padding-top: 40px;
      flex-direction: column; } }

  &__btn {
    width: 100%;
    max-width: 274px;
    margin-right: 40px;

    @include screen-xxl {
      margin-right: 32px;
      max-width: 220px; }

    @include screen-md {
      max-width: 216px;
      margin-right: 20px; }

    @include screen-sm {
      max-width: none;
      margin-right: 0;
      margin-bottom: 20px; }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0; } } }

