.banner {
  background: #000000;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 600ms ease, transform 600ms ease;

  &[data-animated] {
    transform: translateY(0);
    opacity: 1;

    .banner {

      &__icon {
        opacity: 1; }

      &__title {
        opacity: 1;
        transform: translateY(0); }

      &__text {
        opacity: 1;
        transform: translateY(0); }

      &__button {
        opacity: 1;
        transform: translateX(0); } } }

  &--dark {

    .banner {

      &__wrapper {

        &::before {
          background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 15.84%, rgba(0, 0, 0, 0) 84.65%); } }

      &__bg {
        background-position: 50% 14%; }

      &__icon {
        background: #fff; }

      &__caption {

        @include screen-md {
          max-width: 100%; } } } }

  &--light {

    .banner {

      &__wrapper {

        &::before {
          background: linear-gradient(90deg, #00FFC8 41.56%, rgba(83, 240, 206, 0) 70.15%);

          @include screen-lg {
            background: linear-gradient(90deg, #00FFC8 52.46%, rgba(83, 240, 206, 0) 100%); } } }

      &__bg {
        background-position: 100% 37%;
        left: 50%;
        transform: translateX(-26%);

        @include screen-lg {
          background-position: 0 25%;
          transform: translateX(-16%); }

        @include screen-sm {
          transform: translateX(0);
          left: 0;
          background-position: 10% 25%;
          background-size: auto 140%; } }

      &__icon {
        background: #0C100F; }

      &__caption {
        @include screen-sm {
          max-width: 205px; } }

      &__title {
        color: #0C100F; }

      &__text {
        color: #0C100F;
        opacity: 0.7; } } }

  &--company {

    .banner {

      &__wrapper {
        padding: 55px 60px;

        @include screen-md {
          padding: 28px 40px; }

        @include screen-sm {
          padding: 40px 30px; }

        &::before {
          display: none !important; } }

      &__bg {
        background-position: 45% 50%;
        background-size: 120% auto;

        @include screen-lg {
          background-size: 150%;
          background-position: 26% 55%; }

        @include screen-md {
          background-size: 170%;
          background-position: 20% 45%; }

        @include screen-sm {
          background-size: auto 120%;
          background-position: 10% 100%; } }

      &__content {
        padding-left: 160px;

        @include screen-md {
          padding-left: 176px; }

        @include screen-sm {
          padding-left: 0;
          padding-top: 107px; } }

      &__caption {
        max-width: none;
        display: flex;
        justify-content: flex-end;
        padding-right: 100px;

        @include screen-xxl {
          padding-right: 60px; }

        @include screen-lg {
          padding-right: 0;
          justify-content: flex-start; } } } }

  &__wrapper {
    padding: 50px 60px 50px 60px;
    position: relative;
    z-index: 1;

    @include screen-xxl {
      padding: 40px 56px 44px 56px; }

    @include screen-md {
      padding: 20px 30px; }

    @include screen-sm {
      padding: 30px; }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 110%;
      height: 110%;
      transform: translate(-50%, -50%);
      z-index: 1;
      background: linear-gradient(90deg, #000927 27.86%, rgba(30, 39, 119, 0.5) 81.04%); } }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: 50% 70%;
    background-repeat: no-repeat;
    background-size: cover; }

  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;

    @include screen-lg {
      padding-left: 112px;
      flex-direction: column;
      align-items: flex-start; }

    @include screen-md {
      padding-left: 86px; }

    @include screen-sm {
      padding-left: 0; } }

  &__icon {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #53F0CE;
    flex-shrink: 0;
    margin-right: 40px;
    opacity: 0;
    transition: opacity 600ms ease;

    @include screen-xxl {
      width: 80px;
      height: 80px;
      margin-right: 32px; }

    @include screen-lg {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%); }

    @include screen-md {
      width: 56px;
      height: 56px; }

    @include screen-sm {
      position: relative;
      top: auto;
      transform: translateY(0);
      margin-bottom: 20px; }

    img {
      display: block;
      width: 40px;
      height: 40px;
      object-fit: contain;

      @include screen-xxl {
        width: 32px;
        height: 32px; }

      @include screen-md {
        width: 22px;
        height: 22px; } } }

  &__caption {
    width: 100%;
    padding-right: 40px;
    max-width: 596px;

    @include screen-xxl {
      padding-right: 32px; }

    @include screen-lg {
      padding-bottom: 24px; }

    @include screen-md {
      padding-bottom: 13px;
      max-width: 370px; }

    @include screen-sm {
      padding-bottom: 24px; } }

  &__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    padding-bottom: 16px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 600ms ease 300ms, transform 600ms ease 300ms;

    @include screen-xxl {
      font-size: 32px; }

    @include screen-md {
      font-size: 22px;
      padding-bottom: 8px; }

    @include screen-sm {
      font-size: 28px;
      padding-bottom: 12px; }

    &--sm {
      font-size: 32px;
      line-height: 125%;
      padding-bottom: 0;

      @include screen-xxl {
        font-size: 24px; }

      @include screen-md {
        font-size: 18px; } }

    &--lg {
      font-weight: 600;
      font-size: 52px;
      line-height: 130%;
      letter-spacing: -0.01em;
      color: #FFFFFF;
      padding-bottom: 2px;

      @include screen-xxl {
        font-size: 40px; }

      @include screen-md {
        font-size: 22px;
        line-height: 120%;
        padding-bottom: 8px; }

      @include screen-sm {
        font-size: 28px;
        padding-bottom: 12px; } } }

  &__text {
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    opacity: 0.7;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 600ms ease 450ms, transform 600ms ease 450ms;

    @include screen-xxl {
      font-size: 16px; }

    @include screen-md {
      font-size: 13px; }

    @include screen-sm {
      font-size: 15px; }

    &--lg {
      font-weight: 400;
      font-size: 24px;
      line-height: 130%;
      letter-spacing: -0.01em;
      color: #FFFFFF;

      @include screen-xxl {
        font-size: 22px; }

      @include screen-md {
        font-size: 13px; }

      @include screen-sm {
        font-size: 16px; } } }

  &__button {
    flex-shrink: 0;
    margin-left: auto;
    opacity: 0;
    transform: translateX(20px);
    transition: opacity 600ms ease 600ms, transform 600ms ease 600ms;

    @include screen-lg {
      margin-left: 0; }

    @include screen-sm {
      width: 100%; }

    .btn {

      @include screen-md {
        height: 40px;
        padding: 13px 20px;
        min-width: 185px; }

      @include screen-sm {
        height: 48px;
        width: 100%; } }

    .btn__name {

      @include screen-md {
        font-size: 13px; }

      @include screen-sm {
        font-size: 16px; } } } }
