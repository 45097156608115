.help-link {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 50px 0;
  position: relative;

  @include screen-xxl {
    padding: 40px 0; }

  @include screen-md {
    flex-direction: column; }

  body:not(.mobile) &:hover {

    .btn {

      &__name-wrapper {
        display: block;
        max-width: 100%; }

      &__bg {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1.6); }

      &__name {
        color: #0C100F; }

      &__arrow {

        svg {
          stroke: #0C100F; } } } }

  &__icon {
    width: 85px;
    height: 100px;
    flex-shrink: 0;
    margin-right: 50px;
    opacity: 0;
    transition: opacity 600ms ease-in-out;

    @include screen-xxl {
      width: 68px;
      height: 80px;
      margin-right: 40px; }

    @include screen-md {
      order: 1;
      position: absolute;
      top: 81px;
      right: 0;
      margin-right: 0;
      width: 51px;
      height: 60px; }

    @include screen-sm {
      position: relative;
      top: auto;
      margin-bottom: 24px; }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain; } }

  &__caption {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    @include screen-md {
      order: 3; } }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    padding-bottom: 10px;
    opacity: 0;
    transition: opacity 600ms ease-in-out 300ms;

    @include screen-xxl {
      font-size: 20px; }

    @include screen-md {
      padding-bottom: 16px;
      padding-right: 75px; }

    @include screen-sm {
      padding-right: 0; } }

  &__date {
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #868686;
    padding-bottom: 24px;
    opacity: 0;
    transition: opacity 600ms ease-in-out 300ms;

    @include screen-md {
      font-size: 13px; } }

  &__description {
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #CFCFCF;
    padding-bottom: 24px;
    opacity: 0;
    transition: opacity 600ms ease-in-out 600ms; }

  &__value {
    padding: 10px 16px;
    background: #13302A;
    border-radius: 12px;
    flex-shrink: 0;
    font-weight: 500;
    font-size: 22px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #53F0CE;
    margin-left: 55px;
    opacity: 0;
    transition: opacity 600ms ease-in-out 600ms;

    @include screen-xxl {
      font-size: 20px; }

    @include screen-lg {
      margin-left: 26px; }

    @include screen-md {
      font-size: 18px;
      order: 2;
      margin-left: 0;
      margin-bottom: 24px; } }

  .news-card__btn {
    opacity: 0;
    transition: opacity 600ms ease-in-out 600ms; } }

[data-animated] {

  .help-link {

    &__icon {
      opacity: 1; }

    &__title {
      opacity: 1; }

    &__date {
      opacity: 1; }

    &__description {
      opacity: 1; }

    &__value {
      opacity: 1; }

    .news-card__btn {
      opacity: 1; } } }

