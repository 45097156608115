.selectize {

  &-input {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-progress-appearance: none;
    box-shadow: none;
    outline: none;
    display: flex !important;
    align-items: center;
    box-sizing: border-box !important;
    transition: border 300ms ease;
    margin: 0 !important;
    width: 100%;

    height: 53px;
    line-height: 53px;
    border-radius: 10px;
    padding: 0 24px;
    font-size: 16px;
    font-family: $rubik;
    font-weight: 400;
    color: #FFFFFF;
    border: 1px solid #626262;
    background: #15181B !important;

    .js-input.error & {
      border-color: #CC3559; }

    @include screen-md {
      height: 40px;
      line-height: 40px;
      padding: 0 16px;
      font-size: 14px;
      border-radius: 6px; }

    &>input {
      margin: 0 !important;
      font-size: 15px;
      font-family: $rubik;
      color: #FFFFFF;
      box-sizing: border-box !important;

      &::placeholder {
        font-size: 15px;
        color: #fff;
        font-weight: 400;
        opacity: .4;

        @include screen-md {
          font-size: 14px; } } }

    &.dropdown-active {
      border-color: #53F0CE;
      border-radius: 10px !important;

      @include screen-md {
        border-radius: 6px !important; } }

    &.focus {
      box-shadow: none; }

    &::before {
      display: none !important; } }

  &-control.single .selectize-input:after {
    width: 15px;
    height: 15px;
    background: url('../img/selectize/arrow.svg') 50% 50% no-repeat;
    background-size: contain;
    border: none;
    margin-top: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 300ms ease;

    @include screen-md {
      width: 14px;
      height: 14px; } }

  &-control.single .selectize-input.dropdown-active:after {
    margin: 0;
    border: none;
    transform: translateY(-50%) rotate(-180deg); }

  &-dropdown {
    margin: 2px 0;
    border-radius: 8px;
    background: #15181B;
    box-shadow: 0px 7px 25px 0px rgba(0, 0, 0, 0.30);
    overflow: hidden;
    border: none !important;

    @include screen-md {
      border-radius: 6px; }

    &-content {
      padding: 9px 0;
      max-height: 164px;

      &::-webkit-scrollbar {
        width: 4px;
        background-color: rgba(#E9EFF7, 0); }

      &::-webkit-scrollbar-track {
        box-shadow: none; }

      &::-webkit-scrollbar-thumb {
        background: rgba(#D9E0ED, .2);
        border-radius: 3px; } }

    .option {
      color: #fff;
      font-family: $rubik;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;

      padding: 9px 15px;
      cursor: pointer;
      transition: background 300ms ease, color 300ms ease;

      @include screen-md {
        font-size: 12px;
        line-height: 16px; }

      body:not(.mobile) &:hover, &.active {
        background: #15181B;
        color: #53F0CE; }

      &.selected {
        background: rgba(255, 255, 255, 0.05) !important;
        color: #fff !important; } } } }
