.m-about {
  padding: em(120) 0 em(137) 0;
  display: flex;
  align-items: center;
  min-height: 100%;
  position: relative;
  flex: 1;
  // margin-left: -10vw

  @include screen-custom(1491, 1079) {
    padding: em(110) 0 em(127) 0; }

  @include screen-custom(1367, 899) {
    padding: em(50) 0 em(60) 0; }

  @include screen-custom(1281, 767) {
    padding: em(50) 0 em(60) 0; }

  @include screen-custom(1025, 799) {
    padding: em(50) 0 em(60) 0; }

  @include screen-md {
    padding: 60px 0;
    margin: 0; }

  @include screen-sm {
    padding: 30px 0; }

  &__wrapper {
    position: relative; }

  &__content {
    padding-left: em(122);

    @include screen-md {
      padding-left: 0;
      width: 100%;
      max-width: 512px;
      margin: 0 auto; }

    @include screen-sm; }

  &__description {
    width: 100%;
    max-width: em(588);
    position: relative;
    padding-bottom: em(110);

    @include screen-md {
      max-width: 100%;
      padding-bottom: 50px;
      padding-top: 34px;
      transform: translateY(20px);
      opacity: 0;
      transition: transform 600ms ease, opacity 600ms ease;

      &.active {
        opacity: 1;
        transform: translateY(0); } }

    @include screen-sm {
      padding-bottom: 36px; }

    &::before {
      content: '';
      position: absolute;
      top: em(15);
      left: em(-122);
      width: em(80);
      height: 3px;
      background: #fff;

      @include screen-md {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 2px; } }

    a {
      @extend .link; }

    p {
      padding-bottom: em(32);

      @include screen-md {
        padding-bottom: 22px; }

      &:last-child {
        padding-bottom: 0; }

      span {
        font-weight: 300;
        font-size: em(22);
        line-height: 150%;
        letter-spacing: -0.01em;
        color: #CFCFCF;

        @include screen-md {
          display: inline-block;
          font-size: 16px;
          line-height: 140%;
          text-align: center;
          letter-spacing: -0.01em; } } } }

  &__benefits {
    position: relative;

    @include screen-md {
      padding-bottom: 70px; }

    @include screen-sm {
      padding-bottom: 28px; } }

  &__earth {
    position: absolute;
    left: em(850);
    top: em(-2);

    @include screen-md {
      left: 0;
      position: relative;
      top: 0;
      padding-bottom: 500px;
      display: flex;
      flex-direction: column;
      align-items: center; }

    @include screen-sm {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
      overflow: hidden; } }

  &__earth-image {
    position: relative;
    top: em(-74);
    left: em(-120);
    width: em(833);
    height: em(833);
    z-index: 2;

    @include screen-md {
      left: calc(50% - 315px);
      position: absolute;
      top: -50px;
      width: 631px;
      height: 632px;
      background: url('../img/m-about/earth-mobile.png') 50% 35% no-repeat;
      background-size: 606px auto;
      opacity: 0;
      transition: opacity 600ms ease;

      &.active {
        opacity: 1; } }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;

      @include screen-md {
        display: none; } }

    #scene-container-02 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 188%;
      pointer-events: none;
      z-index: 3;

      @include screen-md {
        top: 49%;
        left: 50%;
        width: 152%; }

      &::before {
        content: '';
        display: inline-block;
        padding-top: 58.2%; } }

    canvas {
      position: absolute;
      top: 0%;
      left: 0%;
      width: 100% !important;
      height: 100% !important; } }

  &__earth-bg {
    position: absolute;
    width: em(570);
    height: em(570);
    top: em(140);
    left: em(-53);
    z-index: 1;

    @include screen-md {
      display: none; }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain; } }

  &__earth-badge {
    position: relative;
    z-index: 3; } }
