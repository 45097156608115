.develop {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease;
  position: relative;

  body:not(.mobile) &:hover {

    .develop {

      &__icon {

        &--planet {
          animation: rotate 600ms ease; }

        &--satellite {
          animation: rotate 600ms ease 600ms; } } } }

  &__icons {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 6px;
    align-items: center; }

  &__icon {
    display: block;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    position: relative;

    svg {
      width: 100%;
      height: 100%; }

    &--satellite {
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      top: 0;
      left: 0;

      &::before {
        content: '';
        width: 3px;
        height: 3px;
        border-radius: 50%;
        position: absolute;
        top: 3px;
        left: 3px;
        background: #53F0CE; } } }

  &__text {
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.06em;
    color: #D9D9D9;
    display: block;
    flex-shrink: 0;

    @include screen-lg {
      font-size: 20px; }

    @include screen-md {
      font-size: 16px; }

    strong {
      font-weight: 500; } } }

@keyframes rotate {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(360deg); } }
