.cookies {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10;
  padding: 13px 0;
  background: rgba(#004738, .96);
  display: none;

  @include screen-md {
    padding: 22px 0; }

  @include screen-sm {
    padding: 16px 0; }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    @include screen-sm {
      flex-direction: column; } }

  &__icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;

    @include screen-sm {
      display: none; }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain; } }

  &__text {
    padding: 0 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: #FFFFFF;

    @include screen-md {
      max-width: 446px;
      width: 100%;
      font-size: 14px; }

    @include screen-sm {
      padding: 0 0 12px 0; }

    a {
      font-weight: 500;
      color: #fff;
      transition: color 300ms ease;

      body:not(.mobile) &:hover {
        color: #53F0CE; } } }

  &__button {
    flex-shrink: 0; } }
