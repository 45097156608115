.input {
  position: relative;

  &--sm {

    .input {

      &__field {
        height: 53px;
        line-height: 53px;
        border-radius: 10px;
        background: #15181B;
        border-width: 1px;
        padding: 0 24px;
        font-size: 16px;

        @include screen-md {
          height: 40px;
          line-height: 40px;
          padding: 0 16px;
          font-size: 14px;
          border-radius: 6px; }

        &::placeholder {
          opacity: .4;
          font-weight: 400;
          font-size: 15px;

          @include screen-md {
            font-size: 14px; } } } } }

  &.error {

    .input__field {
      border-color: #CC3559; } }

  &__field {
    width: 100%;
    display: block;
    background: none;
    outline: none !important;
    box-shadow: none;
    transition: border 300ms ease;

    border: 2px solid #626262;
    border-radius: 12px;
    height: 64px;
    line-height: 64px;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: -0.02em;
    color: #FFFFFF;

    padding: 0 30px;

    @include screen-md {
      font-size: 16px;
      height: 56px;
      line-height: 56px;
      padding: 0 20px; }

    &::placeholder {
      color: #fff;
      opacity: 0.4;
      font-weight: 400; }

    &:focus {
      border-color: #53F0CE; } } }
