.tm-slider {
  position: relative;

  &__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    margin-bottom: 80px;
    text-align: center;
    overflow: hidden;

    @include screen-xxl {
      font-size: 32px;
      margin-bottom: 60px; }

    @include screen-md {
      font-size: 28px;
      margin-bottom: 60px; } }

  &__container {

    @include screen-sm {
      margin-left: -20px;
      margin-right: -20px; }

    &[data-animated] {

      .tm-slider {

        &__icon {
          opacity: 1; }

        &__name {
          opacity: 1; }

        &__date {
          opacity: 1; }

        &__description {
          opacity: 1; } } } }

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @include screen-md {
      flex-direction: column;
      align-items: center;
      text-align: center; } }

  &__icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 32px;
    opacity: 0;
    transition: opacity 600ms ease-in-out;

    @include screen-xxl {
      width: 80px;
      height: 80px; }

    @include screen-md {
      margin-right: 0;
      margin-bottom: 24px;
      width: 80px;
      height: 80px; }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__caption {

    @include screen-sm {
      width: 100%;
      max-width: 275px; } }

  &__name {
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    padding-bottom: 8px;
    opacity: 0;
    transition: opacity 600ms ease-in-out 300ms;

    @include screen-md {
      font-size: 18px;
      padding-bottom: 6px; } }

  &__date {
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #868686;
    padding-bottom: 20px;
    opacity: 0;
    transition: opacity 600ms ease-in-out 300ms;

    @include screen-md {
      font-size: 13px;
      padding-bottom: 16px; } }

  &__description {
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    opacity: 0;
    transition: opacity 600ms ease-in-out 600ms;

    @include screen-md {
      font-size: 15px; } }

  &__pagination {
    margin-top: 58px;

    @include screen-xxl {
      margin-top: 40px; } } }
