.thank-slider {
  position: relative;

  &__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    margin-bottom: 80px;
    text-align: center;
    overflow: hidden;

    @include screen-xxl {
      font-size: 32px;
      margin-bottom: 60px; }

    @include screen-md {
      font-size: 28px;
      margin-bottom: 60px; } }

  &__container {

    @include screen-sm {
      margin-left: -20px;
      margin-right: -20px; }

    &[data-animated] {

      .thank-slider {

        &__icon {
          opacity: 1; }

        &__description {
          opacity: 1; }

        &__author {
          opacity: 1; } } } }

  &__pagination {
    margin-top: 58px;

    @include screen-xxl {
      margin-top: 40px; } }

  &__caption {
    width: 100%;

    @include screen-md {
      max-width: 395px;
      margin: 0 auto;
      padding: 20px; } }

  &__icon {
    width: 102px;
    height: 102px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #000000;
    opacity: 0;
    transition: opacity 600ms ease;

    @include screen-xxl {
      width: 80px;
      height: 80px;
      margin-bottom: 24px; }

    img {
      display: block;
      width: 70px;
      height: 70px;
      object-fit: contain;

      @include screen-xxl {
        width: 50px;
        height: 50px; } } }

  &__description {
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    padding-bottom: 48px;
    opacity: 0;
    transition: opacity 600ms ease 300ms;

    @include screen-xxl {
      font-size: 20px;
      padding-bottom: 32px; }

    @include screen-md {
      font-size: 18px; }

    a {
      @extend .link; }

    p {
      padding-bottom: 24px;

      @include screen-md {
        padding-bottom: 20px; }

      &:last-child {
        padding-bottom: 0; } } }

  &__author {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #F2F2F2;
    position: relative;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: opacity 600ms ease 600ms;

    @include screen-xxl {
      font-size: 18px; }

    @include screen-md {
      font-size: 17px; }

    &::after {
      content: '';
      display: inline-block;
      width: 60px;
      height: 2px;
      margin-left: 32px;
      background: #FF9F2F; } } }
