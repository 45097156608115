// @font-face {
//   font-family: 'Neue Alte Grotesk';
//   src: url('../fonts/NeueAlteGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
//        url('../fonts/NeueAlteGrotesk-Regular.woff2') format('woff2'),
//        url('../fonts/NeueAlteGrotesk-Regular.woff') format('woff'),
//        url('../fonts/NeueAlteGrotesk-Regular.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: 'Rubik';
  src: url('../fonts/rubik/Rubik-Regular.woff2') format('woff2'),
       url('../fonts/rubik/Rubik-Regular.woff') format('woff'),
       url('../fonts/rubik/Rubik-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('../fonts/rubik/Rubik-Light.woff2') format('woff2'),
       url('../fonts/rubik/Rubik-Light.woff') format('woff'),
       url('../fonts/rubik/Rubik-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('../fonts/rubik/Rubik-Medium.woff2') format('woff2'),
       url('../fonts/rubik/Rubik-Medium.woff') format('woff'),
       url('../fonts/rubik/Rubik-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('../fonts/rubik/Rubik-SemiBold.woff2') format('woff2'),
       url('../fonts/rubik/Rubik-SemiBold.woff') format('woff'),
       url('../fonts/rubik/Rubik-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('../fonts/rubik/Rubik-Bold.woff2') format('woff2'),
       url('../fonts/rubik/Rubik-Bold.woff') format('woff'),
       url('../fonts/rubik/Rubik-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('../fonts/rubik/Rubik-ExtraBold.woff2') format('woff2'),
       url('../fonts/rubik/Rubik-ExtraBold.woff') format('woff'),
       url('../fonts/rubik/Rubik-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('../fonts/rubik/Rubik-Black.woff2') format('woff2'),
       url('../fonts/rubik/Rubik-Black.woff') format('woff'),
       url('../fonts/rubik/Rubik-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Bebas Neue';
  src: url('../fonts/bebas/BebasNeue-Bold.woff2') format('woff2'),
       url('../fonts/bebas/BebasNeue-Bold.woff') format('woff'),
       url('../fonts/bebas/BebasNeue-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

